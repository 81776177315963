import { useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { useLocation } from 'react-router-dom';

import { Button } from '@mui/material';

function ScrollButton({ children, to, sx }) {
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = () => {
        if (location.pathname === '/home') {
            scroller.scrollTo(to, {
                duration: 500,
                delay: 0,
                smooth: 'easeInOutQuart',
                offset: -70,
            });
        } else {
            navigate('/home', { state: { scrollTo: to } });
        }
    };

    return (
        <Button onClick={handleClick} variant="contained" color="primary" sx={sx}>
            {children}
        </Button>
    );
}

export default ScrollButton;
