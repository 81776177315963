import React from 'react';
import { Box, Grid } from '@mui/material';

import CreatorCardMobile from './CreatorCardMobile';

const CreatorCardsMobile = ({
    creatorList,
    updatePurchasedCreators,
    nextButtonTouched,
    isThisADemo,
    isThisMyCreatorsPage,
}) => {
    return (
        <Box
            id="creatorCardsMobile"
            paddingTop={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Grid
                id="creatorCardsMobileGrid"
                container
                spacing={2}
                paddingBottom={5}
                justifyContent="center"
                alignItems="center"
                sx={{
                    margin: 0,
                    width: '100%',
                }}
            >
                {creatorList &&
                    creatorList.map((creatorItem, index) => {
                        return (
                            <CreatorCardMobile
                                key={`cc_${index}`}
                                creator={creatorItem}
                                updatePurchasedCreators={updatePurchasedCreators}
                                nextButtonTouched={nextButtonTouched}
                                isThisADemo={isThisADemo}
                                isThisMyCreatorsPage={isThisMyCreatorsPage}
                            />
                        );
                    })}
            </Grid>
        </Box>
    );
};

export default CreatorCardsMobile;
