import React, { useState, useEffect, Fragment } from 'react';
import { Card, Stack, Grid, Typography, CardContent } from '@mui/material';

import AppFrame from '../../hoc/AppFrame';
import { getCampaignsAll } from '../../services';
import CampaignList from './CampaignList';
import useAuthorization from '../../hooks/useAuthorization';
import roles from '../../hooks/roles';

const AdminDashboard = () => {
    useAuthorization([roles.ADMIN]);

    const [isLoadingCampaigns, setIsLoadingCampaigns] = useState(true);
    const [brandCampaigns, setBrandCampaigns] = useState(false);

    const countMissions = (campaignList) => {
        let counter = 0;
        campaignList.forEach((campaignItem) => {
            campaignItem.missions.forEach((missionItem) => {
                counter = counter + 1;
            });
        });
        return counter;
    };

    useEffect(() => {
        getCampaignsAll().then((res_campaigns) => {
            setBrandCampaigns(res_campaigns.data);
            setIsLoadingCampaigns(false);
        });
    }, []);

    return (
        <Fragment>
            <Grid container spacing={3}>
                {!isLoadingCampaigns && (
                    <Fragment>
                        <Grid item xs={12} md={12}>
                            <Stack direction="row" spacing={1}>
                                <Card sx={{ minWidth: 150 }}>
                                    <CardContent>
                                        <Typography fontSize="1.5rem">
                                            {`campaigns: ${brandCampaigns.length}`}
                                        </Typography>
                                    </CardContent>
                                </Card>
                                <Card sx={{ minWidth: 150 }}>
                                    <CardContent>
                                        <Typography fontSize="1.5rem">
                                            {`jobs: ${countMissions(brandCampaigns)}`}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Typography variant="h2"> Campaign List</Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <CampaignList brandCampaigns={brandCampaigns} />
                        </Grid>
                    </Fragment>
                )}
            </Grid>
        </Fragment>
    );
};

export default AppFrame(AdminDashboard);
