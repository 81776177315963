import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ReactGA from 'react-ga4';

import {
    Box,
    Dialog,
    IconButton,
    Link,
    Stack,
    TextField,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Chip,
} from '@mui/material';
import { Close, Instagram, Lock } from '@mui/icons-material';

import Button from '../Button';
import PieChart from '../PieChart';
import Payment from '../Payment';

import {
    commentPerPostFormat,
    averageLikePerPostFormat,
    engagementRateFormat,
} from '../../utils/formatters';

const MobileDetailDialog = ({
    open,
    handleDialogOpenClose,
    creator,
    updatePurchasedCreators,
    isThisCreatorPurchased,
    setIsThisCreatorPurchased,
}) => {
    const [pieChartData, setPieChartData] = useState([]);
    const { user } = useSelector((state) => state.userReducer);
    const { voucherCount } = useSelector((state) => state.voucherReducer);

    const handlePurchaseCreatorWithVoucher = async () => {
        const voucherPaymentIntentId = null;
        await updatePurchasedCreators(voucherPaymentIntentId, creator._id);
        setIsThisCreatorPurchased(true);
    };

    useEffect(() => {
        if (!creator.audienceLocations) return;

        const audienceLocationsWithFollowerCountAll = creator.audienceLocations.map((location) => {
            const followers =
                Math.round((creator.followersCount * location.ratioToAllFollowers) / 100) * 100;
            return {
                country: location.country,
                code: location.code,
                ratioToAllFollowers: location.ratioToAllFollowers,
                followers: followers,
                states: location.states,
            };
        });

        const audienceLocationsWithNonZeroFollowerCounts =
            audienceLocationsWithFollowerCountAll.filter((location) => location.followers > 0);

        const usa = audienceLocationsWithNonZeroFollowerCounts.find(
            (location) => location.country === 'USA'
        );
        let topFiveStates = [];
        if (usa) {
            topFiveStates = usa.states
                .sort((a, b) => b.followers - a.followers)
                .slice(0, 5)
                .map((state) => {
                    return {
                        state: state.state,
                        code: state.code,
                        amount: state.amount,
                        ratioToAllFollowers: state.ratioToAllFollowers,
                        count: Math.round((usa.followers * state.ratioToAllFollowers) / 100) * 100,
                    };
                })
                .filter((state) => state.count > 50)
                // filter out if state name is null, undefined or empty string
                .filter(
                    (state) =>
                        state.state &&
                        state.state !== 'null' &&
                        state.state !== 'undefined' &&
                        state.state !== ''
                );

            audienceLocationsWithNonZeroFollowerCounts.find(
                (location) => location.country === 'USA'
            ).states = topFiveStates;
        }

        setPieChartData(audienceLocationsWithNonZeroFollowerCounts);
    }, [creator]);

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleDialogOpenClose}
            sx={{
                zIndex: 5000,
            }}
        >
            {/* Close button */}
            <IconButton
                aria-label="close"
                onClick={handleDialogOpenClose}
                sx={{
                    //maximum zIndex
                    zIndex: 9000,
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <Close />
            </IconButton>
            <Stack
                direction="column"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: '10vh',
                    paddingBottom: '5vh',
                }}
            >
                <Box
                    sx={{
                        width: '200px',
                        height: '200px',
                        borderRadius: '3vw',
                        marginBottom: '3vh',
                        backgroundImage: `url(${creator.profileImage}), url('https://images-vea4cdfsm.s3.eu-central-1.amazonaws.com/avatar.svg')`,
                        backgroundSize: 'auto 100%',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }}
                />

                {creator.name !== 'Private Creator' ? (
                    <Typography
                        id="Dialog-Dialog-title"
                        variant="h6"
                        component="h2"
                        paddingBottom={2}
                        marginTop={1}
                    >
                        {creator.name}
                    </Typography>
                ) : (
                    <Stack paddingBottom={2}>
                        <Chip icon={<Lock />} label="Private Creator" />
                    </Stack>
                )}
                <Stack
                    id={`ccm_instagram_${creator.instagramUsername}`}
                    direction="row"
                    spacing={1}
                >
                    {isThisCreatorPurchased || creator.showProfile ? (
                        <>
                            <Instagram />
                            <Typography variant="body2" color="text.secondary">
                                <Link
                                    href={`http://instagram.com/${creator.instagramUsername}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    underline="hover"
                                >
                                    {creator.instagramUsername}
                                </Link>
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Instagram />
                            <Typography>instagram.com/</Typography>
                            <Typography
                                sx={{
                                    color: 'transparent',
                                    textShadow: '0 0 8px #000',
                                }}
                            >
                                some_creator
                            </Typography>
                        </>
                    )}
                </Stack>
            </Stack>
            <Stack
                id={`creator-stats-${creator.instagramUsername}`}
                direction="row"
                spacing={2}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // paddingTop: '10vh',
                    paddingBottom: '5vh',
                }}
            >
                {/* following stat names   */}
                <Stack direction="column" spacing={1} paddingTop={2}>
                    <Typography>followers</Typography>
                    <Typography>likes/post</Typography>
                    <Typography>comments/post</Typography>
                    <Typography>engagement</Typography>
                    {creator.country && <Stack direction="row">country</Stack>}
                </Stack>
                {/* following stat values */}
                <Stack direction="column" spacing={1} paddingTop={2} sx={{ paddingLeft: '20px' }}>
                    <Typography>{creator.followersCount}</Typography>
                    <Typography>
                        {`${averageLikePerPostFormat(creator.averageLikesOfLastPosts)}`}
                    </Typography>
                    <Typography>
                        {`${commentPerPostFormat(creator.averageCommentsOfLastPosts)}`}
                    </Typography>
                    <Typography>{`${engagementRateFormat(creator.engagementRate)}`}</Typography>
                    <Typography>{creator.country && `${creator.country}`}</Typography>
                </Stack>
            </Stack>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                }}
            >
                <Box width="200px">
                    {pieChartData && pieChartData.length > 0 && (
                        <PieChart data={pieChartData} width={200} height={200} />
                    )}
                </Box>
            </Box>

            <CountryTable countries={pieChartData} />
            <UsaStatesTable countries={pieChartData} />
            {!isThisCreatorPurchased && !creator.showProfile && (!user || voucherCount < 1) && (
                <>
                    <Box
                        fullWidth
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingTop: '20px',
                            paddingBottom: '20px',
                        }}
                    >
                        <Typography textTransform={'uppercase'}>Get this creator</Typography>
                    </Box>

                    <PayForOneCreatorForm
                        updatePurchasedCreators={updatePurchasedCreators}
                        creator={creator}
                        isThisCreatorPurchased={isThisCreatorPurchased}
                        setIsThisCreatorPurchased={setIsThisCreatorPurchased}
                    />
                </>
            )}

            {!isThisCreatorPurchased && !creator.showProfile && user && voucherCount > 0 && (
                <Button
                    onClick={handlePurchaseCreatorWithVoucher}
                    sx={{
                        margin: '0px 20px 30px 20px',
                    }}
                    variant="contained"
                >
                    Get this creator for free
                </Button>
            )}
        </Dialog>
    );
};

const CountryTable = ({ countries }) => {
    return (
        countries &&
        countries.length > 0 && (
            <>
                <Box
                    marginTop={2}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography>Follower share</Typography>
                </Box>
                <Box
                    marginBottom={2}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: '20px',
                    }}
                >
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Country</TableCell>
                                    <TableCell align="right">share</TableCell>
                                    <TableCell align="right">followers</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {countries.map((row) => (
                                    <TableRow
                                        key={row.country}
                                        sx={{
                                            '&:last-child td, &:last-child th': {
                                                border: 0,
                                            },
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.country}
                                        </TableCell>
                                        <TableCell align="right">
                                            {formatRatioToRoundedPercentage(
                                                row.ratioToAllFollowers
                                            )}
                                            %
                                        </TableCell>
                                        <TableCell align="right">~{row.followers}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </>
        )
    );
};

const UsaStatesTable = ({ countries }) => {
    return (
        <>
            {countries &&
                countries.length > 0 &&
                countries.find((location) => location.country === 'USA') && (
                    <div>
                        <Box
                            marginTop={2}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Typography>Follower share in the United States</Typography>
                        </Box>
                        <Box
                            id="chart_data"
                            marginBottom={5}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingTop: '20px',
                            }}
                        >
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>State</TableCell>
                                            <TableCell align="right">share</TableCell>
                                            <TableCell align="right">followers</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* TODO: CHECK FOR USA CASE, NOT ALWAYS FIRST */}
                                        {countries
                                            .find((location) => location.country === 'USA')
                                            .states.map((row) => (
                                                <TableRow
                                                    key={row.state}
                                                    sx={{
                                                        '&:last-child td, &:last-child th': {
                                                            border: 0,
                                                        },
                                                    }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.state}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {formatRatioToRoundedPercentage(
                                                            row.ratioToAllFollowers
                                                        )}
                                                        %
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        ~{row.count}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </div>
                )}
        </>
    );
};

const PayForOneCreatorForm = ({
    updatePurchasedCreators,
    creator,
    isThisCreatorPurchased,
    setIsThisCreatorPurchased,
}) => {
    const [showPayment, setShowPayment] = useState(false);

    const revealPurchasedCreator = async (paymentIntentId, purchasedCreatorId) => {
        updatePurchasedCreators(paymentIntentId, purchasedCreatorId);
        setIsThisCreatorPurchased(true);
    };

    const validationSchema = yup.object({
        name: yup.string('Enter the name').required('Name is required'),
        email: yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required')
            .test('Unique Email', 'Email already in use', async function (email) {
                return true; // check validity of email
            }),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setShowPayment(true);

            ReactGA.event({
                category: 'User',
                action: 'WX: Clicked Pay',
                label: `payment clicking`,
                value: 1,
            });
        },
    });

    return (
        <Box
            sx={{
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingBottom: '60px',
                paddingTop: '0px',
                marginBottom: '30px',
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <Stack direction="column" paddingBottom={3}>
                    <TextField
                        disabled={showPayment}
                        variant="standard"
                        margin="normal"
                        fullWidth
                        id="name"
                        name="name"
                        label="Name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                    <TextField
                        disabled={showPayment}
                        variant="standard"
                        margin="normal"
                        fullWidth
                        id="email"
                        name="email"
                        label="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                </Stack>
                {!showPayment && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                        }}
                    >
                        <Button type="submit" variant="contained">
                            Go to payment
                        </Button>
                    </Box>
                )}
            </form>
            {!!showPayment && (
                <Payment
                    formParams={{
                        ...formik.values,
                    }}
                    creatorId={creator._id}
                    revealPurchasedCreator={revealPurchasedCreator}
                />
            )}
        </Box>
    );
};

function formatRatioToRoundedPercentage(ratio) {
    return (ratio * 100).toFixed(0);
}

export default MobileDetailDialog;
