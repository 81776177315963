import React, { Fragment, useEffect, useState } from 'react';

import {
    Avatar,
    Box,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Link,
    Stack,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import Button from '../../components/Button';

import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import StatusChipBrand from '../../components/StatusChipBrand';
import { OFFER_INVITE } from '../../constants/missionStates';

const ViewDialog = ({ open, handleDialogOpenClose, missionDetails }) => {
    return (
        <Dialog
            fullWidth
            open={open}
            onClose={handleDialogOpenClose}
            sx={{
                '& > * > * ': {
                    maxWidth: '1000px !important',
                },
            }}
        >
            <IconButton
                aria-label="close"
                onClick={handleDialogOpenClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>

            <Box
                sx={{
                    position: 'absolute',
                    left: 30,
                    top: 15,
                }}
            >
                <StatusChipBrand
                    status={
                        missionDetails.invite ? OFFER_INVITE : missionDetails.status.currentStatus
                    }
                />
            </Box>

            <DialogTitle
                id="view-dialog-title"
                variant="h2"
                align="center"
                sx={{
                    paddingBottom: 3,
                }}
            >
                {missionDetails.title} - {missionDetails.creator.name}
            </DialogTitle>

            <DialogContent sx={{ padding: '0' }}>
                <Stack direction="row" spacing={2}>
                    <Stack sx={{ width: '45%' }} direction={'column'} padding={4}>
                        <Box
                            sx={{
                                borderRadius: '15px',
                                width: '80%',
                                height: '330px',
                                backgroundImage: `url(${missionDetails.campaignImageURL})`,
                                backgroundSize: 'auto 100%',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                            }}
                        >
                            {missionDetails.campaignImageURL.length === 0 && (
                                <Stack alignItems="center" paddingTop="180px">
                                    <ImageNotSupportedIcon
                                        sx={{
                                            margin: '0 auto',
                                            height: '100px',
                                            width: '100px',
                                        }}
                                    />
                                </Stack>
                            )}
                        </Box>
                    </Stack>

                    <Stack
                        sx={{ width: '50%' }}
                        direction={'column'}
                        height="330px"
                        style={{
                            overflowX: 'hidden',
                            overflowY: 'auto',
                        }}
                    >
                        {/* <Typography variant="h6">Creator details</Typography> */}

                        <Stack
                            direction="row"
                            paddingLeft={0}
                            paddingBottom={2}
                            paddingTop={2}
                            alignItems="center"
                        >
                            <Avatar
                                sx={{
                                    width: '100px',
                                    height: '100px',
                                }}
                                alt={`image of ${missionDetails.creator.name}`}
                                src={missionDetails.creator.profileImage}
                            />
                            <Stack direction="column">
                                <Typography paddingLeft={2}>
                                    {missionDetails.creator.name}
                                </Typography>
                                <Typography paddingLeft={2}>
                                    instagram:{' '}
                                    <Link
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        underline="hover"
                                        href={`https://www.instagram.com/${missionDetails.creator.instagramUsername}/`}
                                    >
                                        #{missionDetails.creator.instagramUsername}
                                    </Link>
                                </Typography>
                                <Typography paddingLeft={2}>
                                    website:{' '}
                                    <Link
                                        href={missionDetails.creator.website}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        underline="hover"
                                    >
                                        {missionDetails.creator.website}
                                    </Link>
                                </Typography>
                            </Stack>
                        </Stack>

                        <Typography variant="h6" textAlign="center" paddingBottom={2}>
                            Campaign details
                        </Typography>
                        <Typography paddingLeft={2} fontWeight={900} variant="body1">
                            campaign object
                        </Typography>
                        <Typography paddingLeft={2}>{missionDetails.campaignObject}</Typography>
                        <Typography paddingLeft={2} fontWeight={900}>
                            {'description '}
                        </Typography>
                        <Typography paddingLeft={2}>{missionDetails.offerDescription}</Typography>

                        <Typography variant="h6" textAlign="center" paddingBottom={2} marginTop={2}>
                            Preferences
                        </Typography>

                        <Typography paddingLeft={2} fontWeight={900}>
                            {'wishes '}
                        </Typography>
                        <Typography paddingLeft={2}>{missionDetails.wishes}</Typography>

                        <Typography paddingLeft={2} fontWeight={900}>
                            {'notAllowed '}
                        </Typography>
                        <Typography paddingLeft={2}>{missionDetails.notAllowed}</Typography>

                        <Typography variant="h6"> Tasks</Typography>
                        <Stack direction="column" paddingLeft={2}>
                            {missionDetails.tasks.map((task, t_id) => (
                                <Typography key={t_id}>
                                    {task.title}
                                    {task.description && `, ${task.description}`}
                                </Typography>
                            ))}
                        </Stack>

                        <Typography variant="h6" textAlign="center" paddingBottom={2}>
                            Compensation
                        </Typography>
                        <Typography paddingBottom={4}>{missionDetails.compensation}</Typography>
                        <Typography>
                            {missionDetails.endStatement
                                ? `endStatement:${missionDetails.endStatement}`
                                : ''}
                        </Typography>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

const ViewButton = ({ mission }) => {
    const [openViewDialog, setOpenViewDialog] = useState(false);

    const handleViewDialogOpenClose = () => {
        setOpenViewDialog(!openViewDialog);
    };

    return (
        <Fragment>
            <ViewDialog
                open={openViewDialog}
                handleDialogOpenClose={handleViewDialogOpenClose}
                missionDetails={mission}
            />

            <Button onClick={handleViewDialogOpenClose}>
                <Chip label="view " icon={<VisibilityIcon />} />
            </Button>
        </Fragment>
    );
};

const MissionRow = (props) => {
    const { mission } = props;

    return (
        <TableRow>
            <TableCell style={{ width: 70 }}>
                <Avatar
                    alt={`avatar of creator ${mission.creator.name}`}
                    src={mission.creator.profileImage}
                />
            </TableCell>

            <TableCell style={{ width: 200 }}>{mission.creator.instagramUsername}</TableCell>
            <TableCell style={{ width: 210, paddingLeft: '0', paddingRight: '0' }}>
                <StatusChipBrand
                    status={mission.invite ? OFFER_INVITE : mission.status.currentStatus}
                />
            </TableCell>
            <TableCell style={{ width: 150 }}></TableCell>

            <TableCell
                style={{
                    width: 150,
                    paddingLeft: '0',
                    paddingRight: '0',
                }}
            >
                {mission.tasks[0].title}
            </TableCell>

            <TableCell style={{ paddingLeft: '0', paddingRight: '0' }}>
                <ViewButton mission={mission} status={mission.status.currentStatus} />
            </TableCell>
        </TableRow>
    );
};

export default MissionRow;
