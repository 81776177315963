import React, { Fragment } from 'react';
import { Stack, Typography } from '@mui/material';

import InspectCreatorCards from './InspectCreatorCards';

export default () => {
    return (
        <Fragment>
            <Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: (theme) => theme.SideBar.width,
                        width: (theme) => theme.SectionTools.width,
                        height: (theme) => theme.NavBar.height,
                        bgcolor: (theme) => theme.palette.background.main,
                        paddingLeft: (theme) => theme.SectionTools.paddingLeft,
                        zIndex: '5',
                    }}
                >
                    <Typography variant="h2">Inspect Creators</Typography>
                </Stack>
                <InspectCreatorCards isInDashBoard={true} />
            </Stack>
        </Fragment>
    );
};
