import React from 'react';

import { Box, Autocomplete, TextField } from '@mui/material';

export default ({ setLabel, handleApplyNewFilterClick }) => {
    const labelList = [
        {
            iid: 1,
            label: 'champagne',
        },
        {
            iid: 2,
            label: 'wine',
        },
    ];
    const handleLabelSelect = (event, value) => {
        if (value === null) {
            setLabel(null);
        } else {
            setLabel(value.label);
        }
    };

    return (
        <Autocomplete
            onChange={handleLabelSelect}
            id="creator-label-select"
            sx={{
                marginBottom: '9px',
                marginLeft: '9px',
                marginRight: '9px',
                width: 220,
            }}
            options={labelList}
            autoHighlight
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.label}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleApplyNewFilterClick();
                        }
                    }}
                    sx={{
                        '& .css-1sxvwpc-MuiFormLabel-root-MuiInputLabel-root': {
                            marginTop: '7px',
                            marginLeft: '27px',
                        },

                        '& .MuiOutlinedInput-input': {
                            marginLeft: '25px',
                        },
                        '& .MuiAutocomplete-popper': {
                            position: 'absolute',
                            inset: '0px auto auto 0px',
                            width: '220px',
                            margin: '0px',
                            transform: 'translate3d(1500px, 600.5px, 0px)',
                        },
                        '& .MuiOutlinedInput-root': {
                            marginTop: '-7px',
                            marginLeft: '-10px',
                            borderRadius: '50px',
                            backgroundColor: 'white',
                            height: '70px',
                            '&:hover fieldset': {
                                borderColor: 'black',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'primary',
                            },
                        },
                        width: '220px',
                        borderRadius: '25px',
                        marginTop: '7px',
                    }}
                    {...params}
                    label="wine / champagne"
                    inputProps={{
                        ...params.inputProps,
                    }}
                />
            )}
        />
    );
};
