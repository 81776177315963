import { Grid, Typography } from '@mui/material';

import AppFrame from '../../hoc/AppFrame';
import roles from '../../hooks/roles';
import useAuthorization from '../../hooks/useAuthorization';

const RegistrationSuccessPage = () => {
    useAuthorization([roles.PUBLIC]);

    return (
        <Grid container justifyContent="center" spacing={0}>
            <Grid item xs={12} md={12}>
                <Typography
                    align="center"
                    sx={{
                        fontSize: '25px',
                        paddingTop: '30px',
                        marginBottom: '30px',
                        marginTop: '30vh',
                    }}
                >
                    Thank you for connecting WineConx
                </Typography>

                <Typography
                    align="center"
                    sx={{
                        fontSize: '20px',
                        marginBottom: '10px',
                    }}
                >
                    Your registration was successful.
                </Typography>
                <Typography
                    align="center"
                    sx={{
                        fontSize: '20px',
                    }}
                >
                    Please check your email and click the verification link to finalize your
                    registration.
                </Typography>
            </Grid>
        </Grid>
    );
};

export default AppFrame(RegistrationSuccessPage);
