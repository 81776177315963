// create a stats component
import AppFrame from '../../hoc/AppFrame';
import useAuthorization from '../../hooks/useAuthorization';
import roles from '../../hooks/roles';
import { Container, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { getCommentStats } from '../../services/apiBrand';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';

const Stats = () => {
    useAuthorization([roles.BRAND]);

    const workStatistics24Hours = {
        ignoredAmount: '-',
        commentedAmount: '-',
        pendingAmount: '-',
        totalAmount: '-',
        obsoleteAmount: '-',
    };

    const workStatistics7Days = {
        ignoredAmount: '-',
        commentedAmount: '-',
        pendingAmount: '-',
        totalAmount: '-',
        obsoleteAmount: '-',
    };
    const totalStatistics = {
        ignoredAmount: '-',
        commentedAmount: '-',
        pendingAmount: '-',
        totalAmount: '-',
        obsoleteAmount: '-',
    };

    const [stats, setStats] = useState({
        workStatistics24Hours,
        workStatistics7Days,
        totalStatistics,
    });
    useEffect(() => {
        // get stats
        getCommentStats().then((res) => {
            setStats(res.data);
        });
    }, []);
    return (
        <Container
            sx={{
                marginTop: '120px',
                paddingBottom: '120px',
            }}
        >
            <Typography variant="h2" gutterBottom paddingLeft={2} marginBottom={2} >
                Commenting statistics
            </Typography>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Period</TableCell>
                            <TableCell align="right">Commented</TableCell>
                            <TableCell align="right">Ignored</TableCell>
                            <TableCell align="right">Delivered</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>24 hours</TableCell>
                            <TableCell align="right">
                                {stats.workStatistics24Hours.commentedAmount}
                            </TableCell>
                            <TableCell align="right">
                                {stats.workStatistics24Hours.ignoredAmount}
                            </TableCell>
                            <TableCell align="right">{stats.workStatistics24Hours.deliveredAmount}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>7 days</TableCell>
                            <TableCell align="right">
                                {stats.workStatistics7Days.commentedAmount}
                            </TableCell>
                            <TableCell align="right">
                                {stats.workStatistics7Days?.ignoredAmount}
                            </TableCell>
                             <TableCell align="right">
                                {stats.workStatistics7Days?.deliveredAmount}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Total</TableCell>
                            <TableCell align="right">
                                {stats.totalStatistics.commentedAmount}
                            </TableCell>
                            <TableCell align="right">
                                {stats.totalStatistics.ignoredAmount}
                            </TableCell>
                            <TableCell align="right">
                                {stats.totalStatistics.deliveredAmount}
                            </TableCell>

                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default AppFrame(Stats);
