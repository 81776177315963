import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

import * as yup from 'yup';
import ReactGA from 'react-ga4';

import {
    Box,
    Dialog,
    DialogContent,
    Stack,
    IconButton,
    TextField,
    Typography,
    Link,
    DialogTitle,
} from '@mui/material';
import { Close, Instagram } from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';

import Button from '../Button';
import PieChart from '../PieChart/PieChart';
import Payment from '../Payment';

import {
    commentPerPostFormat,
    averageLikePerPostFormat,
    engagementRateFormat,
} from '../../utils/formatters';
import { useSelector } from 'react-redux';

const PayForOneCreatorForm = ({
    updatePurchasedCreators,
    creator,
    isThisCreatorPurchased,
    setIsThisCreatorPurchased,
}) => {
    const navigate = useNavigate();
    const [showPayment, setShowPayment] = useState(false);
    const userObject = useSelector((state) => state.userReducer);
    //const [userIsLoggedIn, setUserIsLoggedIn] = useState(userObject.isLoggedIn);

    const revealPurchasedCreator = async (paymentIntentId, purchasedCreatorId) => {
        updatePurchasedCreators(paymentIntentId, purchasedCreatorId);
        setIsThisCreatorPurchased(true);
    };

    const validationSchema = yup.object({
        name: yup.string('Enter the name').required('Name is required'),
        email: yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required')
            .test('Unique Email', 'Email already in use', async function (email) {
                return true; // check validity of email
            }),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setShowPayment(true);

            ReactGA.event({
                category: 'User',
                action: 'WX: Clicked Pay',
                label: `payment clicking`,
                value: 1,
            });
        },
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Stack direction="column">
                    <TextField
                        disabled={showPayment}
                        margin="normal"
                        fullWidth
                        id="name"
                        name="name"
                        label="Name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                    <TextField
                        disabled={showPayment}
                        margin="normal"
                        fullWidth
                        id="email"
                        name="email"
                        label="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                </Stack>

                {!showPayment && (
                    <Button
                        type="submit"
                        sx={{
                            marginTop: '30px',
                        }}
                        variant="contained"
                    >
                        Go to Payment
                    </Button>
                )}
                {!userObject.isLoggedIn && !showPayment && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            navigate('/register');
                        }}
                        sx={{
                            marginTop: '30px',
                            marginLeft: '10px',
                            borderRadius: '30px',
                            color: 'white',
                        }}
                    >
                        Sign up to get this creator for free
                    </Button>
                )}
            </form>

            {!!showPayment && (
                <Payment
                    formParams={{
                        ...formik.values,
                    }}
                    creatorId={creator._id}
                    revealPurchasedCreator={revealPurchasedCreator}
                />
            )}
        </>
    );
};

const PaymentDialog = ({
    open,
    handleDialogOpenClose,
    creator,
    updatePurchasedCreators,
    isThisCreatorPurchased,
    setIsThisCreatorPurchased,
    isThisADemo,
}) => {
    const [pieChartData, setPieChartData] = useState([]);

    const { user } = useSelector((state) => state.userReducer);
    const { voucherCount } = useSelector((state) => state.voucherReducer);

    useEffect(() => {
        if (!creator.audienceLocations) return;

        const audienceLocationsWithFollowerCount = creator.audienceLocations.map((location) => {
            const followers =
                Math.round((creator.followersCount * location.ratioToAllFollowers) / 100) * 100;
            return {
                country: location.country,
                code: location.code,
                ratioToAllFollowers: location.ratioToAllFollowers,
                followers: followers,
                states: location.states,
            };
        });

        const usa = audienceLocationsWithFollowerCount.find(
            (location) => location.country === 'USA'
        );
        let topFiveStates = [];
        if (usa) {
            topFiveStates = usa.states
                .sort((a, b) => b.followers - a.followers)
                .slice(0, 5)
                .map((state) => {
                    return {
                        state: state.state,
                        code: state.code,
                        amount: state.amount,
                        ratioToAllFollowers: state.ratioToAllFollowers,
                        count: Math.round((usa.followers * state.ratioToAllFollowers) / 100) * 100,
                    };
                })
                .filter((state) => state.count > 50)
                // filter out if state name is null, undefined or empty string
                .filter(
                    (state) =>
                        state.state &&
                        state.state !== 'null' &&
                        state.state !== 'undefined' &&
                        state.state !== ''
                );

            audienceLocationsWithFollowerCount.find(
                (location) => location.country === 'USA'
            ).states = topFiveStates;
        }

        setPieChartData(audienceLocationsWithFollowerCount);
    }, [creator]);

    const handlePurchaseCreatorWithVoucher = async () => {
        const voucherPaymentIntentId = null;
        await updatePurchasedCreators(voucherPaymentIntentId, creator._id);
        setIsThisCreatorPurchased(true);
    };
    return (
        <Dialog
            fullWidth
            open={open}
            onClose={handleDialogOpenClose}
            PaperProps={{
                sx: { maxWidth: '80vw', width: '100%', height: '80vh' },
            }}
        >
            {!isThisCreatorPurchased && !creator.showProfile && (
                <DialogTitle sx={{ paddingLeft: '60px', paddingTop: '40px' }}>
                    Please fill the form to get this creator
                </DialogTitle>
            )}
            <DialogContent sx={{ padding: '60px' }}>
                {!isThisCreatorPurchased && !creator.showProfile && (!user || voucherCount < 1) && (
                    <PayForOneCreatorForm
                        creator={creator}
                        updatePurchasedCreators={updatePurchasedCreators}
                        isThisCreatorPurchased={isThisCreatorPurchased}
                        setIsThisCreatorPurchased={setIsThisCreatorPurchased}
                    />
                )}

                {!isThisCreatorPurchased && !creator.showProfile && user && voucherCount > 0 && (
                    <Button
                        onClick={handlePurchaseCreatorWithVoucher}
                        sx={{
                            marginTop: '30px',
                        }}
                        variant="contained"
                    >
                        Get this creator for free
                    </Button>
                )}

                {/* Close button */}
                <IconButton
                    aria-label="close"
                    onClick={handleDialogOpenClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>

                <Stack direction="column" sx={{ marginTop: '20px' }}>
                    {/* Profile */}
                    <Stack
                        direction="row"
                        // spaces between
                        justifyContent={'space-between'}
                    >
                        <Stack direction="row" spacing={5}>
                            {/* Profile image */}
                            <Box
                                sx={{
                                    width: '200px',
                                    height: '200px',
                                    backgroundImage: `url(${creator.profileImage}), url('https://images-vea4cdfsm.s3.eu-central-1.amazonaws.com/avatar.svg')`,
                                    backgroundSize: 'auto 100%',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                }}
                            />
                            {/* Instagram user */}
                            <Stack direction="column">
                                {isThisCreatorPurchased || creator.showProfile || !isThisADemo ? (
                                    <Typography
                                        id="Dialog-Dialog-title"
                                        variant="h6"
                                        component="h2"
                                        paddingBottom={2}
                                        marginTop={1}
                                    >
                                        {creator.name}
                                    </Typography>
                                ) : (
                                    <Typography
                                        id="Dialog-Dialog-title"
                                        variant="h6"
                                        component="h2"
                                        paddingBottom={2}
                                        marginTop={1}
                                        sx={{
                                            color: 'transparent',
                                            textShadow: '0 0 8px #000',
                                        }}
                                    >
                                        {creator.name}
                                    </Typography>
                                )}

                                <Stack direction="row" spacing={1}>
                                    {isThisCreatorPurchased ||
                                    creator.showProfile ||
                                    !isThisADemo ? (
                                        <>
                                            <Instagram />
                                            <Typography variant="body2" color="text.secondary">
                                                <Link
                                                    href={`http://instagram.com/${creator.instagramUsername}`}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                    underline="hover"
                                                >
                                                    {creator.instagramUsername}
                                                </Link>
                                            </Typography>
                                        </>
                                    ) : (
                                        <>
                                            <Instagram />
                                            <Typography>www.instagram.com/</Typography>
                                            <Typography
                                                sx={{
                                                    color: 'transparent',
                                                    textShadow: '0 0 8px #000',
                                                }}
                                            >
                                                some_creator
                                            </Typography>
                                        </>
                                    )}
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            {/* following stat names   */}
                            <Stack direction="column" spacing={1} paddingTop={2}>
                                <Typography>followers</Typography>
                                <Typography>likes/post</Typography>
                                <Typography>comments/post</Typography>
                                <Typography>engagement</Typography>
                                {creator.country && <Stack direction="row">country</Stack>}
                            </Stack>
                            {/* following stat values */}
                            <Stack
                                direction="column"
                                spacing={1}
                                paddingTop={2}
                                sx={{ paddingLeft: '20px', width: '150px' }}
                            >
                                <Typography>{creator.followersCount}</Typography>
                                <Typography>
                                    {`${averageLikePerPostFormat(creator.averageLikesOfLastPosts)}`}
                                </Typography>
                                <Typography>
                                    {`${commentPerPostFormat(creator.averageCommentsOfLastPosts)}`}
                                </Typography>
                                <Typography>
                                    {`${engagementRateFormat(creator.engagementRate)}`}
                                </Typography>
                                <Typography width="300px">
                                    {creator.country && `${creator.country}`}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    {/* Stats */}
                    <Stack
                        direction="column"
                        spacing={1}
                        sx={{
                            padding: ' 15px 20px',
                        }}
                    >
                        {creator.website && (
                            <Stack direction="row" spacing={1}>
                                <LinkIcon />
                                <Typography variant="body2" color="text.secondary">
                                    <Link
                                        href={`${creator.website}`}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        underline="hover"
                                    >
                                        {creator.website.replace(/^https?:\/\//, '')}
                                    </Link>
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                    {creator.audienceLocations && creator.audienceLocations.length > 0 && (
                        <Stack direction="column" alignItems="center">
                            <Typography
                                variant="h4"
                                color="text.secondary"
                                sx={{
                                    margin: '20px',
                                }}
                            >
                                Audience Locations
                            </Typography>

                            {pieChartData.length > 0 && (
                                <PieChart data={pieChartData} width={400} height={400} />
                            )}
                            {/* <PieChart
                                    data={creator.audienceLocations}
                                    width={400}
                                    height={400}
                                /> */}
                        </Stack>
                    )}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

const FooterMobileRegister = () => {
    const navigate = useNavigate();
    return (
        <Box
            paddingLeft="0"
            paddingRight="0"
            paddingTop="0"
            sx={{
                boxShadow: '0px -25px 20px rgba(255, 255, 255, 1)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                position: 'fixed',
                bottom: 0,
                height: '50px',
                zIndex: '1400000 !important',
                backgroundColor: 'rgba(255, 255, 255, 1)',
            }}
        >
            <Button
                onClick={() => {
                    navigate('/register');
                }}
                variant="contained"
                color="primary"
                className="animated-button"
                sx={{
                    borderRadius: '30px',
                    color: 'white',
                    marginBottom: '10px',
                    backgroundColor: '#062274',
                }}
            >
                REGISTER TO GET FREE CREATORS
            </Button>
        </Box>
    );
};

export default PaymentDialog;
