import React, { useState } from 'react';
import {
    Avatar,
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import MissionCounter from './MissionCounter';
import MissionRow from './MissionRow';

const CampaignRow = (props) => {
    const { campaign } = props;

    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <TableRow
                sx={{
                    '& > *': { borderBottom: 'unset' },
                }}
            >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="medium"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Avatar
                        alt={`avatar of campaign ${campaign.title}`}
                        src={campaign.campaignImageURL}
                    />
                </TableCell>
                <TableCell>{campaign.brand.brandName}</TableCell>
                <TableCell>{campaign.title}</TableCell>
                <TableCell>{campaign.campaignObject}</TableCell>
                <TableCell>
                    <MissionCounter campaign={campaign} />
                </TableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: '#f6f6f6' }}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Box>
                        <Collapse in={open}>
                            <Table size="large" aria-label="campaignTimeline">
                                <TableBody>
                                    {campaign.missions.map((missionItem, mi) => {
                                        const missionWithCampaignData = {
                                            ...missionItem,
                                            title: campaign.title,
                                            campaignObject: campaign.campaignObject,
                                            campaignImageURL: campaign.campaignImageURL,
                                            offerDescription: campaign.offerDescription,
                                            wishes: campaign.wishes,
                                            notAllowed: campaign.notAllowed,
                                        };
                                        return (
                                            <MissionRow
                                                key={`campaign_${campaign.title}_mission_${mi}`}
                                                mission={missionWithCampaignData}
                                            />
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </Box>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export default CampaignRow;
