import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { Grid, Box } from '@mui/material';

import Button from '../../components/Button';
import { setCampaignData } from '../../redux/campaignSetup/actions';
import { wineProducingRegions } from '../../utils/labelDictionary';
import MultiSelectResponsive from '../MultiSelectResponsive';
import { countriesList } from '../../utils/countriesAndStatesList';
import ResponsiveSelect from '../ResponsiveSelect/ResponsiveSelect';
import SwitchSelector from '../SwitchSelector';

const MINIMUM_CREATORS_COUNT_BY_COUNTRY = 12;

const DescriptionFormLoggedIn = ({ isMobile }) => {
    // STATE HANDLING
    const dispatch = useDispatch();
    const { campaign: campaignData } = useSelector((state) => state.campaignSetupReducer);

    const navigate = useNavigate();
    const [nextButtonTouched, setNextButtonTouched] = useState(false);

    // STYLING
    const texfieldVariant = 'standard';
    const requiredFields = [];

    // EVENT HANDLING
    const setSelectedValue = (fieldName, newValue) => {
        dispatch(setCampaignData({ [fieldName]: newValue }));
    };
    const handleNext = () => {
        const missingFields = checkCampaignCompletion({
            campaign: campaignData,
            expectedKeys: requiredFields,
        });

        if (!missingFields.length) {
            navigate('/recommendation');
            //Navigate to next step;
        } else {
            setNextButtonTouched(true);
        }

        const selectedLabels = [];
        if (campaignData.campaignRegion) {
            selectedLabels.push('campaignRegion');
        }
        if (campaignData.targetAudienceLocation) {
            selectedLabels.push('targetAudienceLocation');
        }
        if (campaignData.foodieLabelSelector) {
            selectedLabels.push('foodieLabelSelector');
        }
        if (campaignData.fineWineLabelSelector) {
            selectedLabels.push('fineWineLabelSelector');
        }
        if (campaignData.wineLoverLabelSelector) {
            selectedLabels.push('wineLoverLabelSelector');
        }
        if (campaignData.wineExpertLabelSelector) {
            selectedLabels.push('wineExpertLabelSelector');
        }
        if (campaignData.lifestyleLabelSelector) {
            selectedLabels.push('lifestyleLabelSelector');
        }
        if (campaignData.wineRegionLabelSelector) {
            selectedLabels.push('wineRegionLabelSelector');
        }

        const selectedLabelsString = selectedLabels.filter((item) => !!item).join(', ');

        ReactGA.event({
            category: 'Campaign Setup',
            action: 'WX: Selected labels with details',
            label: 'selectedLabelsString test label',
            value: selectedLabelsString,
        });
    };

    // HELPER FUNCTIONS
    const checkCampaignCompletion = ({ campaign, expectedKeys = [] }) => {
        const missingList = [];
        for (let campkeyindex = 0; campkeyindex < expectedKeys.length; campkeyindex++) {
            if (campaign[expectedKeys[campkeyindex]].length === 0) {
                missingList.push(expectedKeys[campkeyindex]);
            }
        }
        return missingList;
    };
    const areStepFieldsValidated = () => {
        const missingFields = checkCampaignCompletion({
            campaign: campaignData,
            expectedKeys: requiredFields,
        });
        return !missingFields.length;
    };

    return (
        <>
            <Grid
                container
                columns={12}
                spacing={4}
                paddingBottom={12}
                paddingTop={isMobile ? 0 : 6}
            >
                <Grid item xs={12} md={4}>
                    <ResponsiveSelect
                        defaultValue={campaignData.typeOfWine}
                        name="typeOfWine"
                        label="Which type of wine?"
                        options={[
                            'Red',
                            'White',
                            'Rosé',
                            'Sparkling',
                            'Champagne',
                            'Dessert wine',
                            'Biodynamic',
                            'Organic',
                            'Natural wine',
                            'Traditional',
                            'Modern',
                            'Other',
                        ]}
                        isMobile={isMobile}
                        enableNone
                        setChosenOptions={setSelectedValue}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <MultiSelectResponsive
                        isMobile={isMobile}
                        name="creatorTypeBasedOnFollowers"
                        fieldNameLabel="Creator followers base"
                        setChosenOptions={setSelectedValue}
                        defaultValue={campaignData.creatorTypeBasedOnFollowers}
                        nextButtonTouched={nextButtonTouched}
                        variant={texfieldVariant}
                        required={
                            !!requiredFields.find(
                                (expectedKey) => expectedKey === 'creatorTypeBasedOnFollowers'
                            )
                        }
                        multiple={true}
                        optionsLabels={[
                            'Nano (1K to 10K)',
                            'Micro (10K to 80K)',
                            'Macro (more than 80K)',
                        ]}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ResponsiveSelect
                        defaultValue={campaignData.wineProducingRegions}
                        name="wineProducingRegions"
                        label="Where is your wine produced?"
                        options={wineProducingRegions.sort()}
                        isMobile={isMobile}
                        enableNone
                        enableSearch
                        setChosenOptions={setSelectedValue}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <ResponsiveSelect
                        defaultValue={campaignData.campaignRegion}
                        name="campaignRegion"
                        label="Creator is based in"
                        options={countriesList.reduce((countriesToDisplay, countryItem) => {
                            const modifiedCountriewsList = countriesToDisplay;
                            if (countryItem.count > MINIMUM_CREATORS_COUNT_BY_COUNTRY) {
                                modifiedCountriewsList.push(countryItem.country);
                            }
                            return modifiedCountriewsList;
                        }, [])}
                        isMobile={isMobile}
                        enableNone
                        setChosenOptions={setSelectedValue}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ResponsiveSelect
                        defaultValue={campaignData.targetAudienceLocation}
                        name="targetAudienceLocation"
                        label="Target audience location"
                        options={countriesList.reduce((countriesToDisplay, countryItem) => {
                            const modifiedCountriewsList = countriesToDisplay;
                            if (countryItem.count > MINIMUM_CREATORS_COUNT_BY_COUNTRY) {
                                modifiedCountriewsList.push(countryItem.country);
                            }
                            return modifiedCountriewsList;
                        }, [])}
                        isMobile={isMobile}
                        enableNone
                        setChosenOptions={setSelectedValue}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <SwitchSelector
                        name="wineExpertLabelSelector"
                        label="Target wine professionals?"
                        setChosenOptions={setSelectedValue}
                        defaultValue={campaignData.wineExpertLabelSelector}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <SwitchSelector
                        name="wineLoverLabelSelector"
                        label="Target wine lovers?"
                        setChosenOptions={setSelectedValue}
                        defaultValue={campaignData.wineLoverLabelSelector}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <SwitchSelector
                        name="foodieLabelSelector"
                        label="Target fine wine creators?"
                        setChosenOptions={setSelectedValue}
                        defaultValue={campaignData.foodieLabelSelector}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <SwitchSelector
                        name="fineWineLabelSelector"
                        label="Target foodies with wine content?"
                        setChosenOptions={setSelectedValue}
                        defaultValue={campaignData.fineWineLabelSelector}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <SwitchSelector
                        name="lifestyleLabelSelector"
                        label="Target lifestyle creators?"
                        setChosenOptions={setSelectedValue}
                        defaultValue={campaignData.lifestyleLabelSelector}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ResponsiveSelect
                        defaultValue={campaignData.genderLabelSelector}
                        name="genderLabelSelector"
                        label="Which gender to target?"
                        options={['male', 'female', 'other']}
                        isMobile={isMobile}
                        enableNone
                        setChosenOptions={setSelectedValue}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <ResponsiveSelect
                        defaultValue={campaignData.ageLabelSelector}
                        name="ageLabelSelector"
                        label="Which age group to target?"
                        options={['18-24', '25-34', '35-44', '45-54', '55-64', '65+']}
                        isMobile={isMobile}
                        enableNone
                        setChosenOptions={setSelectedValue}
                    />
                </Grid>

                <Grid item xs={12} md={6} />
                <Grid item xs={12} md={6} />
            </Grid>
            <Box
                sx={{
                    backgroundColor: 'white',
                    width: '100vw',
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'fixed',
                    justifyContent: 'flex-end',
                    paddingRight: (theme) => (isMobile ? '5vw' : theme.AppFrame.paddingRight),
                    pt: 2,
                    pb: 10,
                    bottom: 10,
                    left: 0,
                }}
            >
                <Button
                    variant="contained"
                    onClick={handleNext}
                    color={nextButtonTouched && !areStepFieldsValidated() ? 'highlight' : 'primary'}
                >
                    {nextButtonTouched && !areStepFieldsValidated()
                        ? `Next - Missing details`
                        : 'Next'}
                </Button>
            </Box>
        </>
    );
};

export default DescriptionFormLoggedIn;
