import React from 'react';
import { Button } from '@mui/material';

const GreenButton = ({ sx, ...props }) => {
    let color;
    if (props.variant === 'contained') {
        color = 'white';
    } else {
        color = props.color;
    }

    return (
        <Button
            {...props}
            sx={{
                paddingLeft: '50px',
                paddingRight: '50px',
                color: color,
                textTransform: 'none',
                borderRadius: '30px',
                whiteSpace: 'nowrap',
                '&.Mui-disabled': {
                    backgroundColor: '#e0e0e0',
                },

                ...sx,
            }}
        />
    );
};

export default GreenButton;
