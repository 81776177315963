import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wineRegionLabelDictionary } from '../../utils/labelDictionary';

import { Container, Stack, Typography, CircularProgress } from '@mui/material';

import CreatorCardsStepRecommendations from '../../components/CreatorCards/CreatorCardsStepRecommendations';

import { setCampaignData } from '../../redux/campaignSetup/actions';
import { getCreators } from '../../services';

const paginationLimit = 24;
let currentLoadedPage = 0;
let allCreatorsLoaded = false;

const CampaignStepRecommendations = ({
    setActualAmountOfRecommendedCreators,
    nextButtonTouched,
}) => {
    const {
        campaign: campaignData,
        tasks,
        compensation,
    } = useSelector((state) => state.campaignSetupReducer);

    const dispatch = useDispatch();

    const [creatorSearchFilterList, setCreatorSearchFilterList] = useState({
        increasing: 'increasing',
        engagementRates: campaignData.engagementRates,
        creatorTypeBasedOnContent: campaignData.creatorTypeBasedOnContent,
        creatorTypeBasedOnFollowers: campaignData.creatorTypeBasedOnFollowers.map(
            (followersClass) => {
                const followersClassDictionary = {
                    'Nano (1K to 10K)': 'nano',
                    'Micro (10K to 80K)': 'micro',
                    'Macro (more than 80K)': 'macro',
                };
                return followersClassDictionary[followersClass];
            }
        ),
        typeOfWine: campaignData.typeOfWine,
        campaignRegion: campaignData.campaignRegion,
        targetAudienceLocation: campaignData.targetAudienceLocation,

        labelNames: (() => {
            const labelSelections = [];
            if (campaignData.foodieLabelSelector === 'yes') {
                labelSelections.push('foodie');
            }
            if (campaignData.fineWineLabelSelector === 'yes') {
                labelSelections.push('fineWine');
            }
            if (campaignData.lifestyleLabelSelector === 'yes') {
                labelSelections.push('lifestyle');
            }
            if (campaignData.wineLoverLabelSelector === 'yes') {
                labelSelections.push('wineLover');
            }
            if (campaignData.wineExpertLabelSelector === 'yes') {
                labelSelections.push('wineExpert');
            }
            if (campaignData.sommelierLabelSelector === 'yes') {
                labelSelections.push('sommelier');
            }
            if (campaignData.wineRegionLabelSelector !== '') {
                labelSelections.push(
                    wineRegionLabelDictionary[campaignData.wineRegionLabelSelector]
                );
            }

            return labelSelections;
        })(),
        ignoreLiked: campaignData.ignoreLiked,
        ignoreDisliked: campaignData.ignoreDisliked,
    });
    const [creatorList, setCreatorList] = useState(null);
    const [isDisplayed, setIsDisplayed] = useState(false);

    const newCreatorListLoad = async () => {
        const newFilterSet = {
            ...creatorSearchFilterList,
            page: 0,
            limit: paginationLimit,
        };
        setCreatorSearchFilterList(newFilterSet);

        const creators = await getCreators(newFilterSet);

        currentLoadedPage = 0;
        allCreatorsLoaded = false;

        setActualAmountOfRecommendedCreators(creators.data.length);
        setCreatorList(creators.data);

        if (campaignData.reactionsToRecommendedCreators.length > 0) {
            const queryResultCreatorArray = creators.data;
            const actualReactions = campaignData.reactionsToRecommendedCreators;

            const intersection = actualReactions.filter((reactionItem) =>
                queryResultCreatorArray.some(
                    (newCreatorItem) => reactionItem._id === newCreatorItem._id
                )
            );

            dispatch(
                setCampaignData({
                    ...campaignData,
                    reactionsToRecommendedCreators: intersection,
                })
            );
        }
    };

    const isNewCreatorInCampaign = (creator) => {
        const foundCreator = campaignData.missions.find(
            (mission) => mission.creator._id === creator._id
        );
        return !!foundCreator;
    };

    const removeCreatorFromCampaign = (creatorItem) => {
        const newCampaignData = {
            ...campaignData,
            missions: campaignData.missions.filter(
                (missionItem) => missionItem.creator._id !== creatorItem._id
            ),
        };
        dispatch(setCampaignData(newCampaignData));
    };

    const updateReactionsToRecommendedCreators = (reactionToCreator) => {
        const { reaction, _id, creatorData } = reactionToCreator;
        let updatedReactions = campaignData.reactionsToRecommendedCreators;

        const existingItemIndex = updatedReactions.findIndex(
            (reactionItem) => reactionItem._id === _id
        );

        const thereIsNoReactionToCreator = existingItemIndex < 0;

        if (thereIsNoReactionToCreator) {
            updatedReactions.push({
                _id,
                reaction,
                creatorData,
            });
        } else {
            updatedReactions[existingItemIndex].reaction = reaction;
        }

        dispatch(
            setCampaignData({
                ...campaignData,
                reactionsToRecommendedCreators: updatedReactions,
            })
        );
    };

    const addCreatorToCampaign = (creatorItem) => {
        if (!isNewCreatorInCampaign(creatorItem)) {
            const newMission = {
                status: 'DRAFT',
                creator: creatorItem,
                tasks: [{ title: tasks }],
                compensation: compensation,
                fulfillmentProof: '',
                endStatement: '',
            };
            const newCampaignData = {
                ...campaignData,
                missions: [newMission, ...campaignData.missions],
            };
            dispatch(setCampaignData(newCampaignData));
            console.log(newCampaignData);
        } else {
            console.log('Creator is already in the list !');
        }
    };

    useEffect(() => {
        if (!!creatorList && creatorList.length === 0) {
            setIsDisplayed(true);
        }
    }, [creatorList]);

    useEffect(() => {
        newCreatorListLoad();
    }, []);

    return (
        <Stack paddingTop={8}>
            <Stack height="35px" />
            <Stack>
                {!!creatorList && creatorList.length === 0 && isDisplayed && (
                    <Typography
                        align="center"
                        sx={{
                            fontSize: '20px',
                        }}
                    >
                        No creators with these requirements
                    </Typography>
                )}
                {!creatorList && !isDisplayed && (
                    <Stack sx={{ alignItems: 'center' }}>
                        <CircularProgress />
                    </Stack>
                )}

                {creatorList && creatorList.length > 0 && (
                    <Typography
                        sx={{
                            marginBottom: '30px',
                            marginLeft: '30px',
                        }}
                    >
                        Please like or dislike the recommended creators to save the good ones for
                        your campaign
                    </Typography>
                )}

                <CreatorCardsStepRecommendations
                    creatorList={creatorList}
                    handleReactionButton={updateReactionsToRecommendedCreators}
                    handleAddButton={addCreatorToCampaign}
                    handleRemoveButton={removeCreatorFromCampaign}
                    nextButtonTouched={nextButtonTouched}
                />
            </Stack>
            <Stack height="100px" />
        </Stack>
    );
};

export default CampaignStepRecommendations;
