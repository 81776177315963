import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Box, Stack, TextField, Typography } from '@mui/material';
import Button from '../../components/Button';
import AppFrame from '../../hoc/AppFrame';
import { updateBrandProfile } from '../../services';
import roles from '../../hooks/roles';
import useAuthorization from '../../hooks/useAuthorization';

const ProfileBrand = () => {
    useAuthorization([roles.BRAND]);

    const [isEditing, setIsEditing] = useState(false);
    const { user } = useSelector((state) => state.userReducer);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancelEdit = async () => {
        setIsEditing(false);
        formik.setValues({
            brandName: user.brand.brandName,
            email: user.personal.email,
            username: user.personal.username,
            website: user.brand.website,
            phone: user.brand.phone,
            address: user.brand.address,
        });
    };

    const validationSchema = yup.object({
        brandName: yup.string('Enter your brandName'),
        email: yup.string('Enter your email'),
        username: yup.string('Enter your username'),
        website: yup.string('Enter your website'),
        phone: yup.string('Enter your phone'),
        address: yup.string('Enter your address'),
    });

    const formik = useFormik({
        initialValues: {
            brandName: '',
            email: '',
            username: '',
            website: '',
            phone: '',
            address: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                await updateBrandProfile(values);
                // TODO: refresh page. Then hook will take care of updating the profile information state

                setIsEditing(false);
            } catch (error) {
                console.log(error);
            }
        },
    });

    useEffect(() => {
        let profileData;
        if (user) {
            if (user.brand) {
                profileData = {
                    brandName: user.brand.brandName,
                    email: user.personal.email,
                    username: user.personal.username,
                    website: user.brand.website,
                    phone: user.brand.phone,
                    address: user.brand.address,
                };
            } else {
                console.error('invalid acces for brand data');
            }
            if (profileData) {
                formik.setValues(profileData);
            }
        }
    }, [user]);

    return (
        <Box
            sx={{
                margin: '20vh auto 0vh auto',
                alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: '20%',
                width: '350px',
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <Typography
                    variant="h1"
                    sx={{
                        marginBottom: '20px',
                        fontSize: '28px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        paddingBottom: '20px',
                    }}
                >
                    Profile
                </Typography>

                <TextField
                    disabled={!isEditing}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="brandName"
                    name="brandName"
                    label="Name of your company"
                    value={formik.values.brandName}
                    placeholder={formik.values.brandName}
                    onChange={formik.handleChange}
                    error={formik.touched.brandName && Boolean(formik.errors.brandName)}
                    helperText={formik.touched.brandName && formik.errors.brandName}
                />

                <TextField
                    // disabled={!isEditing}
                    disabled={true}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="email"
                    name="email"
                    label="Name of your company"
                    value={formik.values.email}
                    // value='soma.hargitai@wineconx.com'
                    placeholder={formik.values.email}
                    // placeholder='email bla placeholder'
                    onChange={formik.handleChange}
                    error={formik.touched.brandName && Boolean(formik.errors.brandName)}
                    helperText={formik.touched.brandName && formik.errors.brandName}
                />

                <TextField
                    disabled={!isEditing}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="username"
                    name="username"
                    label="Username"
                    value={formik.values.username}
                    placeholder={formik.values.username}
                    onChange={formik.handleChange}
                    error={formik.touched.username && Boolean(formik.errors.username)}
                    helperText={formik.touched.username && formik.errors.username}
                />

                <TextField
                    sx={{
                        marginTop: '8px',
                    }}
                    disabled={!isEditing}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="website"
                    name="website"
                    label="Website"
                    value={formik.values.website}
                    placeholder={formik.values.website}
                    onChange={formik.handleChange}
                    error={formik.touched.website && Boolean(formik.errors.website)}
                    helperText={formik.touched.website && formik.errors.website}
                />
                <TextField
                    sx={{
                        marginTop: '8px',
                    }}
                    disabled={!isEditing}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="phone"
                    name="phone"
                    label="Phone"
                    value={formik.values.phone}
                    placeholder={formik.values.phone}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                />

                <TextField
                    sx={{
                        marginTop: '8px',
                    }}
                    disabled={!isEditing}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="address"
                    name="address"
                    label="Address"
                    value={formik.values.address}
                    placeholder={formik.values.address}
                    onChange={formik.handleChange}
                    error={formik.touched.address && Boolean(formik.errors.address)}
                    helperText={formik.touched.address && formik.errors.address}
                />

                {isEditing && (
                    <Stack direction="row" justifyContent="space-between" marginTop="10px">
                        <Button color="primary" variant="contained" type="submit">
                            Save
                        </Button>
                        <Button color="secondary" variant="contained" onClick={handleCancelEdit}>
                            Cancel
                        </Button>
                    </Stack>
                )}
            </form>
            {!isEditing && (
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleEdit}
                    sx={{
                        marginTop: '10px',
                        marginBottom: '20px',
                        width: '100%',
                        ':hover': {
                            bgcolor: 'primary.main', // theme.palette.primary.main
                            color: 'white',
                        },
                    }}
                >
                    Edit
                </Button>
            )}
        </Box>
    );
};

export default AppFrame(ProfileBrand);
