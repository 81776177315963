import React from 'react';

import { Box, Autocomplete, TextField } from '@mui/material';

const DropdownSelector = ({
    setChosenOptions,
    defaultValue,
    nextButtonTouched,
    required,
    name,
    fieldNameLabel,
    optionsLabels,
    multiple,
}) => {
    const optionsList = optionsLabels.map((label) => {
        return { label: label };
    });
    const handleOptionSelect = (event, value) => {
        if (value === null) {
            setChosenOptions(name, multiple ? [] : '');
        } else {
            setChosenOptions(name, multiple ? value.map((option) => option.label) : value.label);
        }
    };

    return (
        <Autocomplete
            disabled={false}
            onChange={handleOptionSelect}
            multiple={multiple}
            id={name}
            sx={{
                width: '100%',
            }}
            options={optionsList}
            autoHighlight
            value={
                multiple
                    ? defaultValue
                        ? defaultValue.map((value) => {
                              return { label: value };
                          })
                        : []
                    : defaultValue
                    ? { label: defaultValue }
                    : null
            }
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            renderOption={(props, option) => (
                <Box
                    component="li"
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                    name={name}
                >
                    {option.label}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    required={required}
                    error={required && nextButtonTouched && !defaultValue}
                    helperText={
                        required &&
                        nextButtonTouched &&
                        !defaultValue &&
                        `${fieldNameLabel} cannot be empty`
                    }
                    variant="outlined"
                    sx={{
                        '& .css-1sxvwpc-MuiFormLabel-root-MuiInputLabel-root': {
                            marginTop: '',
                            marginLeft: '',
                        },

                        '& .MuiOutlinedInput-input': {
                            marginLeft: '',
                        },
                        '& .MuiAutocomplete-popper': {
                            position: 'absolute',
                            inset: '0px auto auto 0px',
                            width: '',
                            margin: '0px',
                            transform: 'translate3d(1500px, 600.5px, 0px)',
                        },
                        '& .MuiOutlinedInput-root': {
                            marginTop: '',
                            marginLeft: '',
                            // borderRadius: '50px',
                            backgroundColor: 'white',
                            height: '',
                            '&:hover fieldset': {
                                borderColor: 'black',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'primary',
                            },
                        },
                        // width: '100%',
                        maxWidth: '400px',
                        // borderRadius: '25px',
                        marginTop: '',
                    }}
                    {...params}
                    label={fieldNameLabel}
                    inputProps={{
                        ...params.inputProps,
                    }}
                />
            )}
        />
    );
};

export default DropdownSelector;
