import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
    Alert,
    Box,
    IconButton,
    InputAdornment,
    Link,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';

import { logInFirebase, logOutFirebase } from '../../services/firebase';
import Button from '../../components/Button';
import roles from '../../hooks/roles';
import useAuthorization from '../../hooks/useAuthorization';
import AppFrame from '../../hoc/AppFrame';

import { PrivacyTip as PrivacyTipIcon, Info } from '@mui/icons-material';

const Login = () => {
    useAuthorization([roles.PUBLIC]);

    const [displayAlertWrongCredentials, setDisplayAlertWrongCredentials] = useState(false);
    const [alertNotVerifiedEmail, setAlertNotVerifiedEmail] = useState(false);

    const validationSchema = yup.object({
        email: yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required'),

        password: yup.string('Enter your password').required('Password is required'),
    });
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            showPassword: false,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                const { user } = await logInFirebase(values.email, values.password);
                if (!user) {
                    console.log('Error: Firebase call finished without user');
                    setDisplayAlertWrongCredentials(true);
                } else if (!user.emailVerified) {
                    console.log('User email is not verified');
                    setAlertNotVerifiedEmail(true);
                    logOutFirebase();
                }
                // else { console.log('[Login] Loggin in...'); }
            } catch (error) {
                console.log(error);
                setDisplayAlertWrongCredentials(true);
            }
        },
    });

    return (
        <Box
            sx={{
                margin: '25vh auto 0vh auto',
                alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: '20%',
                width: '350px',
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <Typography
                    variant="h1"
                    sx={{
                        marginBottom: '20px',
                        fontSize: '28px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        paddingBottom: '20px',
                    }}
                >
                    Sign in
                </Typography>

                <TextField
                    sx={{
                        marginTop: '8px',
                    }}
                    variant="outlined"
                    type="email"
                    margin="normal"
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.email &&
                        Boolean(formik.errors.email) &&
                        !displayAlertWrongCredentials
                    }
                    helperText={formik.touched.email && formik.errors.email}
                    autoComplete="email"
                />
                <TextField
                    sx={{ marginTop: '8px' }}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    type={formik.values.showPassword ? 'text' : 'password'}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    autoComplete="current-password"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    sx={{
                                        marginRight: '-6px',
                                    }}
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                        formik.setFieldValue(
                                            'showPassword',
                                            !formik.values.showPassword
                                        );
                                    }}
                                    edge="end"
                                >
                                    {formik.values.showPassword ? (
                                        <VisibilityIcon />
                                    ) : (
                                        <VisibilityOffIcon />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                {alertNotVerifiedEmail && !displayAlertWrongCredentials && (
                    <Alert severity="warning">
                        Your email is not verified. Check your inbox to confirm your registration.
                    </Alert>
                )}
                <Stack direction="row" justifyContent="space-between">
                    <Button
                        sx={{
                            marginTop: '10px',
                            marginBottom: '20px',
                            width: '100%',
                        }}
                        color="primary"
                        variant="contained"
                        type="submit"
                    >
                        Sign in
                    </Button>
                </Stack>
                {displayAlertWrongCredentials && (
                    <Box marginTop={'5px'} marginBottom={'5px'}>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: '12px',
                                color: 'error.main',
                            }}
                        >
                            Wrong credentials. Try with a different email or password.
                        </Typography>
                    </Box>
                )}

                <Box marginTop={'20px'}>
                    <ResetPasswordBlock />
                </Box>
            </form>
        </Box>
    );
};

const ResetPasswordBlock = () => {
    const navigate = useNavigate();

    return (
        <Stack
            direction={'row'}
            sx={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    backgroundColor: 'rgb(255 255 255 / 10%)',
                    marginRight: '10px',
                }}
            >
                <IconButton
                    onClick={() => {
                        navigate('/reset-password');
                    }}
                    aria-label="info"
                    size="small"
                    sx={{
                        '&:hover': {
                            color: 'primary.main',
                        },
                    }}
                >
                    <PrivacyTipIcon />
                </IconButton>
            </Box>

            <Stack
                direction={'column'}
                sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="body1" sx={{ fontSize: '10px' }}>
                    Forgot your password?
                </Typography>
                <Link
                    href="/reset-password"
                    underline="none"
                    sx={{
                        fontSize: '12px',
                    }}
                >
                    {' Reset Password'}
                </Link>
            </Stack>
        </Stack>
    );
};

export default AppFrame(Login);
