import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { Element as ScrollElement, scroller } from 'react-scroll';
import {
    Button,
    Stack,
    Box,
    TextField,
    Typography,
    Link as MuiLink,
    Grid,
    useMediaQuery,
} from '@mui/material';
import {
    Instagram as InstagramIcon,
    LinkedIn as LinkedInIcon,
    Email as EmailIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { sendEmail } from '../../services';
import useAuthorization from '../../hooks/useAuthorization';
import { Notification } from '../../components';
import roles from '../../hooks/roles';
import {
    BlockHeading,
    BlockSubHeading,
    ContactIconButton,
    MainH1FirstLine,
    MainH1SecondLine,
    MainHeadingHalfContainer,
    Section,
    SectionHalfContainer,
    SectionImageHalfContainer,
    SectionImageWideContainer,
    SectionMidContainer,
} from './Parts';
import AppFrame from '../../hoc/AppFrame';
import ScrollHomeButton from '../../components/ScrollHomeButton';

const buttonStyle = {
    padding: '16px 54px',
    font: 'normal normal 400 16px/19px Rubik',
    borderRadius: '40px',
};

const useStyles = makeStyles((theme) => ({
    textField: {
        '& .MuiInputBase-input': {
            backgroundColor: 'white',
        },
    },
    messageField: {
        backgroundColor: 'white',
    },
    buttonFilled: {
        padding: '16px 54px',
        font: 'normal normal 400 16px/19px Rubik',
        borderRadius: '40px',
        color: 'white',
        backgroundColor: '#de4564',
        '&:hover': { backgroundColor: '#e85f7b' },
    },
    buttonOutlined: {
        padding: '16px 54px',
        font: 'normal normal 400 16px/19px Rubik',
        borderRadius: '40px',
        color: '#de4564',
        border: '1px solid',
        '&:hover': {
            color: 'white',
            backgroundColor: '#e85f7b',
            borderColor: '#de4564',
        },
    },
    footerText: {
        fontSize: '14px',
        // [theme.breakpoints.up('lg')]: { fontSize: '14px', },
        color: '#b9b9b9',
        '&:hover': { color: 'black' },
    },
}));

const Home = () => {
    useAuthorization([roles.PUBLIC]);
    const location = useLocation();

    const [isMobile, setIsMobile] = useState(false);
    const { campaign: campaignData } = useSelector((state) => state.campaignSetupReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.state?.scrollTo) {
            scroller.scrollTo(location.state.scrollTo, {
                duration: 2500,
                delay: 0,
                smooth: 'easeInOutQuart',
                offset: -70,
            });
        }
    }, [location.state]);

    const classes = useStyles();

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)');
        const handleMediaQueryChange = (mediaQuery) => setIsMobile(mediaQuery.matches);
        handleMediaQueryChange(mediaQuery);
        mediaQuery.addListener(handleMediaQueryChange);
        return () => mediaQuery.removeListener(handleMediaQueryChange);
    }, []);

    return (
        <>
            <Section bgC="white" paddingTop="150px" paddingBottom="200px">
                <MainHeadingHalfContainer>
                    <MainH1FirstLine textAlign={{ xs: 'center', lg: 'left' }}>
                        Social Media
                    </MainH1FirstLine>
                    <MainH1SecondLine textAlign={{ xs: 'center', lg: 'left' }}>
                        for Wine Brands
                    </MainH1SecondLine>

                    <BlockSubHeading>
                        Focus on crafting exquisite wines, we handle your online presence. Connect
                        with the best influencers for your brand.
                    </BlockSubHeading>

                    <Stack
                        direction="row"
                        justifyContent={{
                            xs: 'center',
                            lg: 'flex-start',
                        }}
                        spacing={4}
                        sx={{
                            marginLeft: { xs: '0', lg: '-15px' },
                            marginTop: '40px',
                            marginBottom: '60px',
                        }}
                    >
                        <ScrollHomeButton
                            to="contact"
                            variant="contained"
                            sx={{
                                ...buttonStyle,
                                mx: 2,
                                px: 4,
                                fontFamily: 'Rubik',
                                fontSize: '14px',
                                height: '48px',
                                fontWeight: 450,
                                color: 'white',
                                backgroundColor: '#de4564',
                                display: {
                                    xs: 'none',
                                    md: 'inline-block',
                                },
                                borderRadius: '25px',
                                '&:hover': {
                                    backgroundColor: '#e85f7b',
                                },
                            }}
                        >
                            Contact us now
                        </ScrollHomeButton>
                    </Stack>
                </MainHeadingHalfContainer>
                <SectionImageWideContainer>
                    <img
                        src="https://wineconximages.s3.eu-central-1.amazonaws.com/website/website_100.jpg"
                        alt="Influencer Marketing Network"
                        width="100%"
                        style={{ borderRadius: '30px' }}
                    />
                </SectionImageWideContainer>
            </Section>

            <Section bgC="#f5f5f5">
                <SectionHalfContainer>
                    <BlockHeading>
                        Finding the Best Influencers for Your Brand Marketing Campaigns
                    </BlockHeading>
                    <BlockSubHeading>
                        Wineconx identifies the most suitable influencers for your brand's marketing
                        campaigns involving a thorough process of research, evaluation, and
                        selection. This process begins by understanding your brand's target audience
                        and objectives.
                    </BlockSubHeading>
                </SectionHalfContainer>
                <SectionImageHalfContainer>
                    <img
                        src="https://wineconximages.s3.eu-central-1.amazonaws.com/website/website_81.png"
                        alt="find influencers"
                        width="100%"
                        style={{ borderRadius: '30px' }}
                    />
                </SectionImageHalfContainer>
            </Section>

            <Section bgC="white" paddingTop="70px" paddingBottom="100px">
                <Stack direction="column" spacing={4}>
                    <SectionImageWideContainer>
                        <Box
                            // if xl or l: paddingX="20%"
                            paddingX={{
                                xs: '0',
                                sm: '0',
                                md: '0',
                                lg: '20%',
                                xl: '20%',
                            }}
                        >
                            <img
                                src="https://wineconximages.s3.eu-central-1.amazonaws.com/website/website_91.png"
                                alt="Influencer Marketing Network"
                                width="100%"
                                style={{
                                    borderRadius: '30px',
                                    marginBottom: '0px',
                                }}
                            />
                        </Box>
                    </SectionImageWideContainer>
                    <SectionMidContainer>
                        <BlockHeading align="center" marginBottom="40px">
                            Analyzing the creator's <br /> Engagement and Audience
                        </BlockHeading>
                        <BlockSubHeading>
                            Before partnering with influencers, it's essential to thoroughly analyze
                            their engagement metrics and audience demographics to ensure
                            compatibility with your brand's objectives.
                        </BlockSubHeading>
                        <BlockSubHeading>
                            By leveraging Wineconx data analytics you can gain deeper insights into
                            the creator's content performance, and engagement trends, enabling you
                            to make informed decisions when selecting influencers for your marketing
                            campaigns.
                        </BlockSubHeading>
                    </SectionMidContainer>
                </Stack>
            </Section>

            <Section bgC="#f5f5f5">
                <SectionImageHalfContainer>
                    <img
                        src="https://wineconximages.s3.eu-central-1.amazonaws.com/website/website_102.png"
                        alt="detailed report"
                        width="100%"
                        style={{ borderRadius: '30px' }}
                    />
                </SectionImageHalfContainer>
                <SectionHalfContainer>
                    <BlockHeading>
                        Wineconx provides a detailed report on the Audience of the Creators
                    </BlockHeading>
                    <BlockSubHeading>
                        Wineconx offers a comprehensive report that dives into the audience of
                        creators, providing valuable insights into their follower base.
                    </BlockSubHeading>
                    <BlockSubHeading>
                        This report goes beyond surface-level metrics to provide a detailed
                        breakdown of follower distribution by location, allowing brands to
                        understand the geographic reach of the influencer's audience.
                    </BlockSubHeading>
                </SectionHalfContainer>
            </Section>

            <Section bgC="white">
                <SectionHalfContainer>
                    <BlockHeading>
                        Selection is based on the number of Followers, Likes, and Comments
                    </BlockHeading>
                    <BlockSubHeading>
                        WineConX influencer ranking is determined by a variety of factors, including
                        the number of followers, likes, comments, and overall engagement metrics of
                        the creators.
                    </BlockSubHeading>
                    <BlockSubHeading>
                        Using WineConX data-driven insights and comparative analysis can help you
                        prioritize influencers whose audience location and metrics align closely
                        with your brand's target market, ensuring maximum impact for marketing
                        campaigns.
                    </BlockSubHeading>
                </SectionHalfContainer>
                <SectionImageHalfContainer>
                    <img
                        src="https://wineconximages.s3.eu-central-1.amazonaws.com/website/website_54.png"
                        alt="ranking"
                        width="100%"
                        style={{ borderRadius: '30px' }}
                    />
                </SectionImageHalfContainer>
            </Section>

            <Section bgC="#f5f5f5">
                <SectionImageHalfContainer>
                    <img
                        src="https://wineconximages.s3.eu-central-1.amazonaws.com/website/website_61.png"
                        alt="analysis"
                        width="100%"
                        style={{ borderRadius: '30px' }}
                    />
                </SectionImageHalfContainer>
                <SectionHalfContainer>
                    <BlockHeading>Analyze the success of your campaign</BlockHeading>
                    <BlockSubHeading>
                        By monitoring follower changes and statistics based on engagement, WineConX
                        provides accurate data analytics on how successful each campaigns is so you
                        can evaluate progress and optimize efforts.
                    </BlockSubHeading>
                </SectionHalfContainer>
            </Section>

            <Section bgC="white" paddingTop="50px" paddingBottom="72px">
                <SectionHalfContainer>
                    <BlockHeading>
                        Increase the engagement for <br /> greater visibility on Instagram
                    </BlockHeading>
                    <BlockSubHeading>
                        The best way to raise online brand awareness is to make your Instagram
                        profile loved by your network. We provide AI-tools to find the best profiles
                        and posts to create organic growth. Wineconx produces the engaging comments
                        for you to reach the wider community, getting results effortlessly.
                    </BlockSubHeading>
                </SectionHalfContainer>
                <SectionImageHalfContainer>
                    <img
                        src="https://wineconximages.s3.eu-central-1.amazonaws.com/website/website_73.jpg"
                        alt="engagement"
                        width="100%"
                        style={{ borderRadius: '30px' }}
                    />
                </SectionImageHalfContainer>
            </Section>

            {/* Contact section with contact message form */}
            <Section
                // add contact as id to scroll to this section
                bgC="#f5f5f5"
                paddingTop="50px"
                paddingBottom="50px"
            >
                <SectionMidContainer
                    sx={
                        {
                            // border: '5px solid black',
                        }
                    }
                >
                    <EmailForm />
                </SectionMidContainer>
            </Section>
            <FooterDesktop />
        </>
    );
};

const EmailForm = () => {
    const classes = useStyles();

    // Form fields
    const [text, setText] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [country, setCountry] = useState('');

    // Notification
    const [showNotification, setShowNotification] = useState(false);
    const [notificationType, setNotificationType] = useState('success');
    const notificationMessages = {
        success: 'Email sent successfully',
        error: 'Error happened while sending email',
    };

    const resetForm = () => {
        setText('');
        setFirstName('');
        setLastName('');
        setEmail('');
        setCompanyName('');
        setCountry('');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await sendEmail(text, firstName, lastName, email, companyName, country);

            setNotificationType('success');
            setShowNotification(true);
            resetForm();
        } catch (error) {
            setNotificationType('error');
            setShowNotification(true);
        }
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
                mt: 3,
                width: '100%',
                paddingX: '20px',
                paddingY: '40px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
            }}
        >
            <ScrollElement name="contact">
                <BlockHeading id="contact" align="center" marginBottom="40px">
                    Contact Us
                </BlockHeading>
            </ScrollElement>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        onChange={(e) => setFirstName(e.target.value)}
                        value={firstName}
                        className={classes.textField}
                        label="First Name"
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        onChange={(e) => setLastName(e.target.value)}
                        value={lastName}
                        className={classes.textField}
                        label="Last Name"
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        className={classes.textField}
                        label="Email"
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        onChange={(e) => setCompanyName(e.target.value)}
                        value={companyName}
                        className={classes.textField}
                        label="Company"
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        onChange={(e) => setCountry(e.target.value)}
                        value={country}
                        className={classes.textField}
                        label="Country"
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        onChange={(e) => setText(e.target.value)}
                        className={classes.messageField}
                        label="Message"
                        fullWidth
                        multiline
                        rows={4}
                        value={text}
                        required
                    />
                </Grid>
            </Grid>
            <Button
                type="submit"
                variant="contained"
                className={classes.buttonFilled}
                sx={{
                    mt: 6,
                }}
            >
                Send Message
            </Button>

            <Notification
                open={showNotification}
                message={notificationMessages[notificationType]}
                variant="outlined"
                onClose={() => setShowNotification(false)}
                type={notificationType}
            />
        </Box>
    );
};

const FooterDesktop = () => {
    const theme = useTheme();
    const classes = useStyles();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    paddingX: '10px',
                    paddingTop: '40px',
                    paddingBottom: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <ContactIconButton
                    MuiIconComponent={InstagramIcon}
                    href="https://instagram.com/wineconx"
                    title="Instagram"
                />
                <ContactIconButton
                    MuiIconComponent={LinkedInIcon}
                    href="https://de.linkedin.com/company/wineconx"
                    title="LinkedIn"
                />
                <ContactIconButton
                    MuiIconComponent={EmailIcon}
                    href="mailto:support@wineconx.xom"
                    title="Email"
                />
            </Box>
            <Stack
                direction={{
                    xs: 'row',
                    lg: 'row',
                }}
                justifyContent="center"
                alignItems="center"
                spacing={3}
                sx={{
                    width: '100%',
                    height: {
                        xs: '100px',
                        lg: '50px',
                    },
                    marginBottom: {
                        xs: '0px',
                        lg: '25px',
                    },
                    backgroundColor: 'white',
                }}
            >
                <Typography className={classes.footerText}>©&nbsp;2023&nbsp;WINECONX</Typography>
                <MuiLink href="/privacy" underline="none" className={classes.footerText}>
                    {isLgUp ? 'Privacy Policy' : 'Privacy'}
                </MuiLink>
                <MuiLink href="/terms" underline="none" className={classes.footerText}>
                    {isLgUp ? 'Terms and Conditions' : 'Terms'}
                </MuiLink>
            </Stack>
        </>
    );
};

export default AppFrame(Home);
