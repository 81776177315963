import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LinkIcon from '@mui/icons-material/Link';
import InstagramIcon from '@mui/icons-material/Instagram';
import { RemoveCircle } from '@mui/icons-material';
import {
    Box,
    Card,
    CardActions,
    CardActionArea,
    Dialog,
    DialogContent,
    Grid,
    Stack,
    Tooltip,
    IconButton,
    Typography,
    Link,
} from '@mui/material';

import Button from '../Button';

const CreatorDialog = ({
    open,
    handleDialogOpenClose,
    handleSelectCreator,
    handleUnselectCreator,
    creator,
    isSelected,
    isInDashBoard,
}) => {
    const [selected, setSelected] = useState(isSelected);

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={handleDialogOpenClose}
            sx={{
                '& > * > *': {
                    borderRadius: '10px',
                    maxWidth: '800px',
                },
            }}
        >
            <DialogContent sx={{ padding: '0' }}>
                <IconButton
                    aria-label="close"
                    onClick={handleDialogOpenClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Stack direction="row">
                    <Box
                        sx={{
                            width: '300px',
                            height: '300px',
                            backgroundImage: `url(${creator.profileImage})`,
                            backgroundSize: 'auto 100%',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                    />

                    <Stack direction="column" sx={{ padding: ' 15px 20px' }}>
                        <Typography
                            id="Dialog-Dialog-title"
                            variant="h6"
                            component="h2"
                            paddingBottom={2}
                        >
                            {creator.name}
                        </Typography>

                        <Stack direction="row" spacing={1}>
                            <InstagramIcon />
                            <Typography variant="body2" color="text.secondary">
                                <Link
                                    href={`http://instagram.com/${creator.instagramUsername}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    underline="hover"
                                >
                                    {creator.instagramUsername}
                                </Link>
                            </Typography>
                        </Stack>

                        {creator.website && (
                            <Stack direction="row" spacing={1}>
                                <LinkIcon />
                                <Typography variant="body2" color="text.secondary">
                                    <Link
                                        href={`${creator.website}`}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        underline="hover"
                                    >
                                        {creator.website.replace(/^https?:\/\//, '')}
                                    </Link>
                                </Typography>
                            </Stack>
                        )}

                        {selected && !isInDashBoard && (
                            <Tooltip
                                title="Remove this creator from your campaign"
                                placement="top-end"
                            >
                                <IconButton
                                    aria-label="remove"
                                    onClick={() => {
                                        handleUnselectCreator(creator);
                                        setSelected(false);
                                    }}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        bottom: 8,
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                    <RemoveCircle />
                                </IconButton>
                            </Tooltip>
                        )}
                        {!selected && !isInDashBoard && (
                            <Tooltip title="Add this creator to your campaign" placement="top-end">
                                <IconButton
                                    aria-label="add"
                                    onClick={() => {
                                        handleSelectCreator();
                                        setSelected(true);
                                    }}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        bottom: 8,
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                    <AddCircleIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

const CreatorCard = ({
    creatorItem,
    updateCreatorConcealData,
    updateCreatorUtfIssueData,
    updateDuplicateData,
    index,
    isInDashBoard,
}) => {
    /**
     * TODO: move selection to global state, handle it in SelectedCard
     */
    const [isSelected, setIsSelected] = useState(false);
    const [irrelevantCreatorState, setIrrelevantCreatorState] = useState(false);
    const [utfIssueCreatorState, setUtfIssueCreatorState] = useState(false);
    const [duplicateState, setDuplicateState] = useState(false);

    useEffect(() => {
        if (creatorItem.irrelevant) {
            setIrrelevantCreatorState(true);
        }
        if (creatorItem.utfIssue) {
            setUtfIssueCreatorState(true);
        }
        if (creatorItem.duplicate) {
            setDuplicateState(true);
        }
    }, [creatorItem]);

    const formatNumbersToKiloDotDecimal = (number) => {
        let formattedNumber = parseInt(number);

        let postfixValue;
        let postfixedText;

        if (formattedNumber < 1000000) {
            postfixValue = Math.round(formattedNumber / 100) / 10;
            postfixedText = `${postfixValue} K`;
        } else {
            postfixValue = Math.round(formattedNumber / 100000) / 10;
            postfixedText = `${postfixValue} M`;
        }

        return postfixedText;
    };

    const handleIrrelevantConceal = async (updateData) => {
        await updateCreatorConcealData(creatorItem._id, updateData);
        setIrrelevantCreatorState(!irrelevantCreatorState);
    };

    const handleUtfIssueConceal = async (updateData) => {
        await updateCreatorUtfIssueData(creatorItem._id, updateData);
        setUtfIssueCreatorState(!utfIssueCreatorState);
    };

    const handleDuplicateConceal = async (updateData) => {
        await updateDuplicateData(creatorItem._id, updateData);
        setDuplicateState(!duplicateState);
    };

    const handleClick = () => {
        setIsSelected(!isSelected);
    };

    const [open, setOpen] = useState(false);
    const handleDialogOpenClose = () => setOpen(!open);

    return (
        <Grid item xs={12} sm={6} md={6} lg={4}>
            <Card
                sx={{
                    border:
                        irrelevantCreatorState || utfIssueCreatorState || duplicateState
                            ? '2px solid red'
                            : '',
                    borderRadius: '1vw',
                    padding: '0.5vw',
                    paddingBottom: '0.75vw',
                    '&:hover': {
                        zIndex: '20000',
                        boxShadow: '3vw 12vh 10rem #CCCCCC',
                        transform: 'scale(1.01)',
                    },
                }}
            >
                <CardActions
                    sx={{
                        zIndex: '5',

                        paddingTop: '3px',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                    }}
                >
                    <Box
                        width="100%"
                        display="flex"
                        flexWrap="wrap"
                        justifyContent="space-evenly"
                        sx={{
                            '& *': {
                                marginBottom: '7px !important',
                                marginRight: '5px !important',
                                fontSize: '12px',
                            },
                        }}
                    >
                        <Button
                            variant="outlined"
                            aria-label="irrelevant_creator_conceal"
                            onClick={() => {
                                handleIrrelevantConceal(!irrelevantCreatorState);
                                setIrrelevantCreatorState(!irrelevantCreatorState);
                            }}
                            sx={{
                                width: '100px',
                                paddingLeft: '0px',
                                paddingRight: '0px',
                                color: irrelevantCreatorState ? 'red' : 'green',
                                borderColor: irrelevantCreatorState ? 'red' : 'green',
                                '&:hover': {
                                    backgroundColor: irrelevantCreatorState ? 'green' : 'red',
                                    color: irrelevantCreatorState ? 'beige' : 'beige',
                                },
                            }}
                        >
                            {irrelevantCreatorState ? 'IRRELEVANT' : 'RELEVANT'}
                        </Button>
                        <Button
                            variant="outlined"
                            aria-label="utfissue_creator_conceal"
                            onClick={() => {
                                handleUtfIssueConceal(!utfIssueCreatorState);
                                setUtfIssueCreatorState(!utfIssueCreatorState);
                            }}
                            sx={{
                                width: '100px',
                                color: utfIssueCreatorState ? 'red' : 'green',
                                borderColor: utfIssueCreatorState ? 'red' : 'green',
                                '&:hover': {
                                    backgroundColor: utfIssueCreatorState ? 'green' : 'red',
                                    color: utfIssueCreatorState ? 'beige' : 'beige',
                                },
                            }}
                        >
                            {utfIssueCreatorState ? 'UTF ISSUE' : 'UTF OK'}
                        </Button>
                        <Button
                            variant="outlined"
                            aria-label="duplicate_creator_conceal"
                            onClick={() => {
                                handleDuplicateConceal(!duplicateState);
                                setDuplicateState(!duplicateState);
                            }}
                            sx={{
                                width: '120px',
                                color: duplicateState ? 'red' : 'green',
                                borderColor: duplicateState ? 'red' : 'green',
                                '&:hover': {
                                    backgroundColor: duplicateState ? 'green' : 'red',
                                    color: duplicateState ? 'beige' : 'beige',
                                },
                            }}
                        >
                            {duplicateState ? 'DUPLICATE' : 'NO DUPLICATE'}
                        </Button>
                    </Box>
                </CardActions>

                <CardActionArea
                    sx={{
                        height: '140px',
                        '&:before': {
                            content: "''",
                            display: 'block',
                            paddingTop: '100%' /* initial ratio of 1:1*/,
                        },
                        backgroundImage: `url(${creatorItem.profileImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        borderRadius: '1vw',
                        marginBottom: '1.1vh',
                    }}
                    onClick={handleDialogOpenClose}
                />
                <Stack style={{ padding: '10px 14px 5px 10px' }} spacing={1}>
                    <Typography
                        noWrap
                        gutterBottom
                        variant="h6"
                        component="div"
                        sx={{ fontSize: '1rem' }}
                    >
                        {creatorItem.name ? creatorItem.name : creatorItem.instagramUsername}
                    </Typography>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Stack direction="row" spacing={1}>
                            <Stack direction="column">
                                <Typography>IG user:</Typography>
                                <Typography>IG id:</Typography>
                                <Typography>followers:</Typography>
                                <Typography>city:</Typography>
                            </Stack>
                            <Stack direction="column">
                                <Typography>{creatorItem.instagramUsername}</Typography>
                                <Typography>{creatorItem.instagramId}</Typography>
                                <Typography>
                                    {creatorItem.followersCount.toLocaleString()}
                                </Typography>
                                <Typography>{creatorItem.city}</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Card>
            <CreatorDialog
                open={open}
                handleDialogOpenClose={handleDialogOpenClose}
                handleSelectCreator={handleClick}
                handleUnselectCreator={() => {}}
                creator={creatorItem}
                isSelected={isSelected}
                isInDashBoard={isInDashBoard}
            />
        </Grid>
    );
};

export default CreatorCard;
