import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    Box,
    Container,
    Alert,
    Avatar,
    Paper,
    Stack,
    Typography,
    Table,
    TableCell,
    TableBody,
    TableRow,
    TableContainer,
    TableHead,
} from '@mui/material';
import { Instagram } from '@mui/icons-material';

import Button from '../../components/Button';
import { commentPerPostFormat, averageLikePerPostFormat } from '../../utils/formatters';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import CreatorCardsMobile from '../../components/CreatorCardsMobile';

const SelectedCreatorRow = ({ creator }) => {
    return (
        <TableRow>
            <TableCell>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar
                        alt={`creator profile image of ${creator.name}`}
                        src={creator.profileImage}
                    />
                    <Typography>{creator.name}</Typography>
                </Stack>
            </TableCell>
            <TableCell align="left" sx={{ paddingLeft: '16px', paddingRight: '3px' }}>
                <Button
                    onClick={() =>
                        window.open(`http://instagram.com/${creator.instagramUsername}`, '_blank')
                    }
                    startIcon={<Instagram />}
                    size="small"
                    sx={{
                        color: 'white',
                        backgroundColor: '#8E24AA',
                        '&:hover': {
                            backgroundColor: '#5E35B1',
                        },
                    }}
                >
                    {creator.instagramUsername}
                </Button>
            </TableCell>
            <TableCell sx={{ paddingLeft: '16px', paddingRight: '3px' }}>
                {creator.country}
            </TableCell>
            <TableCell sx={{ paddingLeft: '6px' }} align="center">
                {commentPerPostFormat(creator.averageCommentsOfLastPosts)}
            </TableCell>
            <TableCell align="center" style={{ paddingLeft: '16px', paddingRight: '30px' }}>
                {averageLikePerPostFormat(creator.averageLikesOfLastPosts)}
            </TableCell>
        </TableRow>
    );
};

const CampaignStepRecommendationCheckout = () => {
    const { campaign: campaignData, campaignMissingFields: campaignMissingFields } = useSelector(
        (state) => state.campaignSetupReducer
    );

    const [likedCreatorList, setLikedCreatorList] = useState([]);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Function to handle the media query change
        const handleMediaQueryChange = (mediaQuery) => {
            setIsMobile(mediaQuery.matches);
        };

        // Create a media query that checks if the screen width is less than or equal to 768 pixels
        const mediaQuery = window.matchMedia('(max-width: 768px)');

        // Initial check
        handleMediaQueryChange(mediaQuery);

        // Add a listener to detect changes in the media query
        mediaQuery.addListener(handleMediaQueryChange);

        // Clean up the listener when the component unmounts
        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, []);

    useEffect(() => {
        const likedCreatorsOfCampaign = campaignData.reactionsToRecommendedCreators.filter(
            (item) => item.reaction === 1
        );

        setLikedCreatorList(likedCreatorsOfCampaign.map((item) => item.creatorData));
    }, [campaignData]);

    const missingFieldsLabels = {
        title: 'Campaign title',
        campaignObject: 'Campaign goal',
        missions: 'Missions',
        offerDescription: 'Motivation and reasons',
        notAllowed: 'Not allowed',
        wishes: 'Wishes',
    };

    const tableStyles = {
        padding: '3vw',
        borderRadius: '30px',
    };

    return (
        <Stack paddingTop={10}>
            {isMobile ? (
                <>
                    {!!campaignMissingFields.length ? (
                        <Alert
                            severity="warning"
                            sx={{
                                borderRadius: '30px',
                            }}
                        >
                            Missing Fields:
                            {campaignMissingFields.map((field, fi, origin) =>
                                fi === origin.length - 1
                                    ? ` ${missingFieldsLabels[field]}.`
                                    : ` ${missingFieldsLabels[field]},`
                            )}
                        </Alert>
                    ) : null}

                    <Typography marginLeft="16px" fontSize="2rem">
                        {campaignData.title ? `${campaignData.title}` : 'Untitled campaign'}
                    </Typography>
                    <Container sx={tableStyles}>
                        <CreatorCardsMobile
                            creatorList={likedCreatorList}
                            updatePurchasedCreators={() => {}}
                            nextButtonTouched={() => {}}
                            isThisADemo={true}
                            isThisMyCreatorsPage={true}
                        />
                    </Container>
                </>
            ) : (
                <TableContainer component={Paper} sx={tableStyles}>
                    {!!campaignMissingFields.length ? (
                        <Alert
                            severity="warning"
                            sx={{
                                borderRadius: '30px',
                            }}
                        >
                            Missing Fields:
                            {campaignMissingFields.map((field, fi, origin) =>
                                fi === origin.length - 1
                                    ? ` ${missingFieldsLabels[field]}.`
                                    : ` ${missingFieldsLabels[field]},`
                            )}
                        </Alert>
                    ) : null}
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Campaign Title
                                    <Typography fontSize="2rem">
                                        {campaignData.title
                                            ? `${campaignData.title}`
                                            : 'Untitled campaign'}
                                    </Typography>
                                </TableCell>
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        {likedCreatorList.length > 0 ? (
                            <TableHead
                                sx={{
                                    backgroundColor: '#f5f4f7',
                                }}
                            >
                                <TableRow>
                                    <TableCell>
                                        <Stack direction="row" spacing={1}>
                                            <Typography>
                                                Selected Creators that you liked
                                            </Typography>
                                            <ThumbUpAltIcon />
                                        </Stack>
                                    </TableCell>
                                    <TableCell align="left">Instagram ID</TableCell>
                                    <TableCell align="left"> Based in </TableCell>
                                    <TableCell> Comment / post</TableCell>
                                    <TableCell> Like / post</TableCell>
                                </TableRow>
                            </TableHead>
                        ) : null}
                        <TableBody>
                            {likedCreatorList.map((creator, mi) => {
                                return <SelectedCreatorRow key={`mi_${mi}`} creator={creator} />;
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Stack>
    );
};

export default CampaignStepRecommendationCheckout;
