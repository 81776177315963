import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box, TextField, Typography } from '@mui/material';

import { sendPasswordResetEmailFirebase } from '../../services/firebase';
import AppFrame from '../../hoc/AppFrame';
import Button from '../../components/Button';
import roles from '../../hooks/roles';
import useAuthorization from '../../hooks/useAuthorization';

const ResetPassword = () => {
    useAuthorization([roles.PUBLIC]);
    const navigate = useNavigate();

    const [displayAlertWrongCredentials, setDisplayAlertWrongCredentials] = useState(false);

    const validationSchema = yup.object({
        email: yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required'),
    });
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                await sendPasswordResetEmailFirebase(formik.values.email);
                navigate('/reset-password-success');
            } catch (error) {
                console.log(error);
                setDisplayAlertWrongCredentials(true);
            }
        },
    });

    return (
        <Box
            sx={{
                // marginTop: '25vh',
                margin: '25vh auto 0vh auto',
                alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: '20%',
                width: '350px',
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <Typography
                    variant="h1"
                    sx={{
                        marginBottom: '20px',
                        fontSize: '28px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        paddingBottom: '20px',
                    }}
                >
                    Password reset
                </Typography>
                <Typography
                    variant="subtitle1"
                    sx={{
                        textTransform: 'uppercase',
                        marginBottom: '0px',
                        fontSize: '12px',
                    }}
                >
                    Your user email
                </Typography>
                <TextField
                    sx={{ marginTop: '8px' }}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.email &&
                        Boolean(formik.errors.email) &&
                        !displayAlertWrongCredentials
                    }
                    helperText={formik.touched.email && formik.errors.email}
                    autoComplete="email"
                />

                <Button
                    sx={{
                        marginTop: '10px',
                        width: '100%',
                    }}
                    color="error"
                    variant="contained"
                    type="submit"
                >
                    RESET
                </Button>
            </form>
        </Box>
    );
};

export default AppFrame(ResetPassword);
