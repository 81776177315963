import React, { useState, Fragment, useEffect } from 'react';

import {
    Box,
    Collapse,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';

import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';

import CreatorCardsMobile from '../CreatorCardsMobile';
import CampaignRow from './CampaignRow';

const CampaignRecommendationList = ({
    brandCampaignRecommendations,
    removeCreatorFromCampaign,
    removeList,
}) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Function to handle the media query change
        const handleMediaQueryChange = (mediaQuery) => {
            setIsMobile(mediaQuery.matches);
        };

        // Create a media query that checks if the screen width is less than or equal to 768 pixels
        const mediaQuery = window.matchMedia('(max-width: 768px)');

        // Initial check
        handleMediaQueryChange(mediaQuery);

        // Add a listener to detect changes in the media query
        mediaQuery.addListener(handleMediaQueryChange);

        // Clean up the listener when the component unmounts
        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, []);

    const campaignsByDate = brandCampaignRecommendations.sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
    });

    return (
        <>
            {isMobile && <CampaignRecommendationListMobile recommendations={campaignsByDate} />}
            {!isMobile && (
                <CampaignRecommendationListDesktop
                    recommendations={campaignsByDate}
                    removeCreatorFromCampaign={removeCreatorFromCampaign}
                    removeList={removeList}
                />
            )}
        </>
    );
};

const CampaignRecommendationListDesktop = ({
    recommendations,
    removeCreatorFromCampaign,
    removeList,
}) => {
    return (
        <TableContainer component={Box}>
            <Table
                aria-label="collapsible table"
                size="medium"
                sx={{
                    '& .MuiTableCell-root': {
                        height: '18px',
                    },
                    '& > * > * > .MuiTableCell-root': {
                        borderBottom: 'none',
                    },
                }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell
                            align="left"
                            sx={{ fontSize: '20px', fontWeight: 300 }}
                        ></TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {recommendations.map((item, index) => (
                        <CampaignRow
                            key={`ongoingcampaign_${index}`}
                            campaign={item}
                            openDefault={index === 0}
                            removeCreatorFromCampaign={removeCreatorFromCampaign}
                            removeList={removeList}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const CampaignRecommendationListMobile = ({ recommendations }) => {
    return recommendations.map((item, index) => (
        <CampaignRowMobile key={`ongoingcampaign_${index}`} campaign={item} />
    ));
};

const CampaignRowMobile = (props) => {
    const { campaign } = props;
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const createdAt = new Date(campaign.createdAt);
        const now = new Date();
        const diff = now.getTime() - createdAt.getTime();

        if (diff < 5000) {
            setOpen(true);
        }
    }, []);

    return (
        <Fragment>
            <Stack direction="row" alignItems="center">
                <IconButton
                    edge="start"
                    aria-label="expand row"
                    size="medium"
                    onClick={() => setOpen(!open)}
                    sx={{
                        height: '40px',
                        width: '40px',
                        padding: '0px',
                    }}
                >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
                <Typography sx={{ fontSize: '20px' }}>{campaign.title}</Typography>
            </Stack>

            <Box
                sx={{
                    zIndex: '20001',
                    // boxShadow: '0.5vw 0.5vh 5rem #EEE',
                    margin: '0px',
                    // borderRadius: '20px',
                }}
            >
                <Collapse in={open}>
                    <CreatorCardsMobile
                        creatorList={campaign.likedCreators}
                        updatePurchasedCreators={() => {}}
                        nextButtonTouched={() => {}}
                        isThisADemo={true}
                        isThisMyCreatorsPage={true}
                    />
                </Collapse>
            </Box>
        </Fragment>
    );
};

export default CampaignRecommendationList;
