import { IconButton, Stack, Box, Typography } from '@mui/material';

// ContactIconButton for social media links
export const ContactIconButton = ({ MuiIconComponent, href, title }) => (
    <IconButton
        title={title}
        sx={{
            backgroundColor: '#de4564',
            borderRadius: '50%',
            padding: '10px',
            margin: '8px',
            '&:hover': {
                backgroundColor: '#e85f7b',
            },
        }}
        aria-label={title}
        onClick={() => window.open(href, '_blank')}
    >
        <MuiIconComponent sx={{ color: 'white' }} />
    </IconButton>
);

// Typography Styles
const typographyBase = {
    fontFamily: 'Rubik',
    fontWeight: '300',
    lineHeight: '1.4',
};

// Main heading lines
export const MainH1FirstLine = ({ children, sx, textAlign }) => (
    <Typography
        variant="h1"
        textAlign={textAlign}
        sx={{
            ...typographyBase,
            // fontSize: '54px',
            fontSize: {
                xs: '40px',
                md: '54px',
            },
            lineHeight: '1.2',
            marginTop: '24px',
        }}
    >
        {children}
    </Typography>
);

export const MainH1SecondLine = ({ children }) => (
    <Typography
        variant="h1"
        textAlign={{ xs: 'center', lg: 'left' }}
        sx={{
            ...typographyBase,
            fontSize: {
                xs: '40px',
                md: '54px',
            },
            lineHeight: '1.2',
            marginBottom: '16px',
            letterSpacing: '2.5px',
        }}
    >
        {children}
    </Typography>
);

// Section headings and subheadings
export const BlockHeading = ({ align = 'left', marginBottom = '16px', children }) => (
    <Typography
        variant="h3"
        sx={{
            ...typographyBase,
            fontWeight: '400',
            marginBottom,
            textAlign: { xs: 'center', lg: align },
            fontSize: { xs: '24px', lg: '32px' },
        }}
    >
        {children}
    </Typography>
);

export const BlockSubHeading = ({ title, children, textAlign }) => (
    <Typography
        title={title}
        variant="h4"
        sx={{
            ...typographyBase,
            fontWeight: '300',
            fontSize: '17px',
            marginX: { xs: '5vw', lg: '0px' },
            textAlign: { textAlign },
        }}
    >
        {children}
    </Typography>
);

// Section Containers
export const Section = ({
    title,
    children,
    bgC,
    paddingTop = '120px',
    paddingBottom = '100px',
}) => (
    <Box
        title={title}
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            paddingTop: { xs: '72px', lg: paddingTop },
            paddingBottom: { xs: '72px', lg: paddingBottom },
            backgroundColor: bgC,
        }}
    >
        <Box maxWidth="lg" sx={{ width: '100%' }}>
            <Stack
                direction={{ xs: 'column', lg: 'row' }}
                spacing={4}
                justifyContent="center"
                alignItems="center"
            >
                {children}
            </Stack>
        </Box>
    </Box>
);

export const SectionHalfContainer = ({ children }) => (
    <Box
        width={{ xs: '100%', lg: '50%' }}
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <Box width="90%">{children}</Box>
    </Box>
);

export const SectionImageHalfContainer = ({ children }) => (
    <Box
        sx={{
            width: { xs: '80%', md: '50%', lg: '50%' },
            padding: {
                xs: '0px',
                lg: '0px',
            },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <Box
            width={{
                xs: '100%',
                lg: '100%',
            }}
        >
            {children}
        </Box>
    </Box>
);

export const SectionImageWideContainer = ({ children }) => (
    <Box
        sx={{
            width: {
                xs: '100%',
                lg: '100%',
            },
            px: {
                xs: '5vw',
                lg: '10px',
            },
            py: {
                xs: '0px',
                lg: '0px',
            },

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <Box width="100%">{children}</Box>
    </Box>
);

export const SectionMidContainer = ({ children, sx }) => (
    <Box
        width="100%"
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            ...sx,
        }}
    >
        <Box maxWidth="720px">{children}</Box>
    </Box>
);

export const SectionWideContainer = ({ children, sx }) => (
    <Box
        width="100%"
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            ...sx,
        }}
    >
        {children}
    </Box>
);

export const MainHeadingHalfContainer = ({ children }) => (
    <Box
        width={{ xs: '100%', lg: '45%' }}
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'left',
            alignItems: 'left',
        }}
    >
        <Box
            sx={{
                paddingX: '16px',
                width: '100%',
                justifyContent: { xs: 'center', lg: 'left' },
                alignItems: { xs: 'center', lg: 'left' },
            }}
        >
            {children}
        </Box>
    </Box>
);
