import React, { useState, useEffect } from 'react';
import { Box, Card, CardActionArea, Grid, Stack, Typography, Divider, Chip } from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';

import Button from '../Button';
import MobileDetailDialog from '../MobileDetailDialog/MobileDetailDialog';
import { averageLikePerPostFormat } from '../../utils/formatters';
import { Lock } from '@mui/icons-material';

const formatNumbersToKiloDotDecimal = (number) => {
    let formattedNumber = parseInt(number);

    let postfixValue;
    let postfixedText;

    if (formattedNumber < 1000000) {
        postfixValue = Math.round(formattedNumber / 100) / 10;
        postfixedText = `${postfixValue} K`;
    } else {
        postfixValue = Math.round(formattedNumber / 100000) / 10;
        postfixedText = `${postfixValue} M`;
    }

    return postfixedText;
};

const CreatorCardMobile = ({
    creator,
    updatePurchasedCreators,
    nextButtonTouched,
    isThisADemo,
    isThisMyCreatorsPage,
}) => {
    const [isThisCreatorPurchased, setIsThisCreatorPurchased] = useState(isThisMyCreatorsPage);
    const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
    const [mobileDetailDialogOpen, setMobileDetailDialogOpen] = useState(false);

    const handlePaymentDialogOpenClose = () => setPaymentDialogOpen(!paymentDialogOpen);

    const handleMobileDetailDialogOpenClose = () => {
        setMobileDetailDialogOpen(!mobileDetailDialogOpen);
    };

    return (
        <Grid
            id={`creatorCardMobile_${creator.instagramUsername}`}
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
                paddingLeft: '0 !important',
            }}
        >
            <Card
                sx={{
                    margin: 0,
                    paddingLeft: '2vw',
                    paddingRight: '2vw',
                    paddingTop: '1vh',
                    borderRadius: '3vw',
                }}
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    paddingLeft={0}
                    paddingRight={0}
                    marginBottom={1}
                    sx={{
                        height: '4.5vh',
                        width: '100%',
                        borderRadius: '0.5vw',
                    }}
                >
                    {creator.name !== 'Private Creator' ? (
                        <Typography variant="body2" sx={{ paddingLeft: '1vw' }}>
                            {creator.name}
                        </Typography>
                    ) : (
                        <Chip icon={<Lock />} label="Private Creator" />
                    )}
                </Box>
                <Divider />

                <Stack
                    direction="row"
                    spacing={0}
                    width={{
                        xs: '50%',
                        sm: '50%',
                        md: '50%',
                        lg: '50%',
                        xl: '50%',
                    }}
                    paddingTop={1}
                    paddingBottom={1}
                >
                    <Stack
                        direction="row"
                        spacing={3}
                        sx={{
                            position: 'relative',
                        }}
                    >
                        <CardActionArea
                            sx={{
                                '&:before': {
                                    content: "''",
                                },

                                width: '30vw',
                                height: '30vw',
                                backgroundImage: `url(${creator.profileImage}), url('https://images-vea4cdfsm.s3.eu-central-1.amazonaws.com/avatar.svg')`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                borderRadius: '3vw',
                            }}
                            onClick={() => {}}
                        />

                        <Stack
                            id={`creatorCardMobile_${creator.instagramUsername}_followercounts`}
                            direction={'column'}
                            spacing={0}
                        >
                            <Stack direction="row">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignContent: 'center',
                                    }}
                                >
                                    <GroupsIcon
                                        sx={{
                                            marginRight: '8px',
                                        }}
                                    />
                                    <Typography
                                        variant="h7"
                                        sx={{
                                            minWidth: '20vw',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {formatNumbersToKiloDotDecimal(creator.followersCount)}
                                    </Typography>
                                </Box>

                                <Typography
                                    variant="h7"
                                    sx={{
                                        minWidth: '20vw',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {` ${averageLikePerPostFormat(
                                        creator.averageLikesOfLastPosts
                                    )}`}
                                </Typography>
                            </Stack>

                            <Button
                                variant="contained"
                                onClick={handleMobileDetailDialogOpenClose}
                                sx={{
                                    width: '20vw',
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 0,
                                }}
                            >
                                See report
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </Card>
            <MobileDetailDialog
                open={mobileDetailDialogOpen}
                handleDialogOpenClose={handleMobileDetailDialogOpenClose}
                creator={creator}
                updatePurchasedCreators={updatePurchasedCreators}
                isThisCreatorPurchased={isThisCreatorPurchased}
                setIsThisCreatorPurchased={setIsThisCreatorPurchased}
            />
        </Grid>
    );
};

export default CreatorCardMobile;
