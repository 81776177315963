import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid, Stack, Box, Stepper, Step, StepButton, Typography } from '@mui/material';

import Button from '../../components/Button';
import AppFrame from '../../hoc/AppFrame';
import CampaignStepDescription from './stepDescription';
import CampaignStepRecommendations from './stepRecommendedCreators';
import CampaignStepRecommendationCheckout from './stepCheckoutRecommendationCheckout';
import { resetCampaignSetupData } from '../../redux/campaignSetup/actions';
import { postCampaignRecommendation } from '../../services/apiRecommendation';
import roles from '../../hooks/roles';
import useAuthorization from '../../hooks/useAuthorization';

const CampaignSetup = () => {
    useAuthorization([roles.BRAND]);

    const steps = ['Description', 'Recommended Creators', 'Checkout'];

    return (
        <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12} md={12}>
                <HorizontalNonLinearStepper steps={steps} />
            </Grid>
        </Grid>
    );
};

const HorizontalNonLinearStepper = ({ steps }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Function to handle the media query change
        const handleMediaQueryChange = (mediaQuery) => {
            setIsMobile(mediaQuery.matches);
        };

        // Create a media query that checks if the screen width is less than or equal to 768 pixels
        const mediaQuery = window.matchMedia('(max-width: 768px)');

        // Initial check
        handleMediaQueryChange(mediaQuery);

        // Add a listener to detect changes in the media query
        mediaQuery.addListener(handleMediaQueryChange);

        // Clean up the listener when the component unmounts
        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, []);

    const [actualAmountOfRecommendedCreators, setActualAmountOfRecommendedCreators] = useState(0);
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    const [nextButtonTouched, setNextButtonTouched] = useState(false);

    const { campaign: campaignData, campaignMissingFields: campaignMissingFields } = useSelector(
        (state) => state.campaignSetupReducer
    );

    const requiredFieldsByStep = [['title']];

    const checkCampaignCompletion = ({ campaign, expectedKeys = ['title'] }) => {
        const missingList = [];
        for (let campkeyindex = 0; campkeyindex < expectedKeys.length; campkeyindex++) {
            if (campaign[expectedKeys[campkeyindex]].length === 0) {
                missingList.push(expectedKeys[campkeyindex]);
            }
        }
        return missingList;
    };

    const checkIfHaveCompletedReactions = () => {
        const amountOfReactions = campaignData.reactionsToRecommendedCreators.length;
        return amountOfReactions === actualAmountOfRecommendedCreators;
    };

    const saveCampaignRecommendation = async () => {
        const dislikedCreators = campaignData.reactionsToRecommendedCreators
            .filter((reactionObject) => reactionObject.reaction === 0)
            .map((reactionObject) => reactionObject._id);
        const likedCreators = campaignData.reactionsToRecommendedCreators
            .filter((item) => item.reaction === 1)
            .map((reactionObject) => reactionObject._id);
        const recommendedCreators = campaignData.reactionsToRecommendedCreators.map(
            (reactionObject) => reactionObject._id
        );

        const campaignRecommendationToSave = {
            title: campaignData.title,
            recommendedCreators,
            likedCreators,
            dislikedCreators,
        };
        try {
            const createdCampaignRecommendation = await postCampaignRecommendation(
                campaignRecommendationToSave
            );

            if (createdCampaignRecommendation) {
                dispatch(resetCampaignSetupData());
            } else {
                console.error(
                    'Cannot reset redux state. No campaign recommendation returned from mongodb'
                );
            }
            navigate('/brand/campaignlist');
        } catch (error) {
            console.log('error on saving recommendation campaign');
        }
    };

    const stepButtonValidationMessage = (activeStep) => {
        if (activeStep === 0) {
            return 'Missing details';
        } else if (activeStep === 1) {
            return 'Please click a reaction for every creator';
        }
    };

    const areStepFieldsValidated = (activeStep) => {
        if (activeStep === 0) {
            const missingFields = checkCampaignCompletion({
                campaign: campaignData,
                expectedKeys: requiredFieldsByStep[activeStep],
            });
            return !missingFields.length;
        } else if (activeStep === 1) {
            return checkIfHaveCompletedReactions();
        }
    };

    const StepButtons = () => (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <Button
                variant="contained"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
            >
                Back
            </Button>

            {activeStep === 2 ? (
                <Button onClick={saveCampaignRecommendation} variant="contained">
                    Save
                </Button>
            ) : (
                <Button
                    variant="contained"
                    disabled={activeStep === 2}
                    onClick={handleNext}
                    sx={{ mr: 1 }}
                    color={
                        nextButtonTouched && !areStepFieldsValidated(activeStep)
                            ? 'highlight'
                            : 'primary'
                    }
                >
                    {nextButtonTouched && !areStepFieldsValidated(activeStep)
                        ? `Next - ${stepButtonValidationMessage(activeStep)}`
                        : 'Next'}
                </Button>
            )}
        </Box>
    );

    const handleNext = () => {
        if (activeStep === 0) {
            const missingFields = checkCampaignCompletion({
                campaign: campaignData,
                expectedKeys: requiredFieldsByStep[activeStep],
            });

            if (!missingFields.length) {
                setNextButtonTouched(false);
                setActiveStep(1);
            } else {
                setNextButtonTouched(true);
            }
        } else if (activeStep === 1) {
            const finishedScreen = checkIfHaveCompletedReactions();

            if (finishedScreen) {
                setNextButtonTouched(false);
                setActiveStep(2);
            } else {
                setNextButtonTouched(true);
            }
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    return (
        <Box>
            <Stack
                top="0"
                direction="row"
                alignItems="center"
                width={(theme) => theme.SectionTools.width}
                height={(theme) => theme.NavBar.height}
                paddingLeft={(theme) => theme.NavBar.paddingLeft}
                paddingRight={(theme) => theme.NavBar.paddingRight}
                sx={{
                    bgcolor: (theme) => theme.palette.background.main,
                    position: 'fixed',
                    zIndex: '12',
                }}
            >
                <Stack
                    direction="column"
                    width="100%"
                    paddingTop="2.3vh"
                    paddingLeft="3vw"
                    paddingRight="10vw"
                    sx={{
                        zIndex: '12001',
                    }}
                >
                    <Stepper activeStep={activeStep} sx={{ marginBottom: 2 }}>
                        {steps.map((label, index) => (
                            <Step key={label} completed={completed[index]}>
                                <StepButton color="inherit" onClick={handleStep(index)}>
                                    {label}
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                </Stack>
            </Stack>
            <StepSwitch
                setActualAmountOfRecommendedCreators={setActualAmountOfRecommendedCreators}
                stepIndex={activeStep + 1}
                nextButtonTouched={nextButtonTouched}
                requiredFieldsByStep={requiredFieldsByStep}
            />
            <Stack
                backgroundColor="background.main"
                bottom="48px"
                height="50px"
                left={isMobile ? '0' : (theme) => theme.SideBar.width}
                width={isMobile ? '100%' : (theme) => `calc(100% - ${theme.SideBar.width})`}
                direction="column"
                paddingLeft="3vw"
                paddingRight="3vw"
                paddingTop={1}
                sx={{
                    position: 'fixed',
                    zIndex: 333,
                }}
            >
                <StepButtons />
            </Stack>
        </Box>
    );
};

const StepSwitch = ({
    setActualAmountOfRecommendedCreators,
    stepIndex,
    nextButtonTouched,
    requiredFieldsByStep,
}) => {
    switch (stepIndex) {
        case 1:
            return (
                <CampaignStepDescription
                    nextButtonTouched={nextButtonTouched}
                    requiredFields={requiredFieldsByStep[0]}
                />
            );
        case 2:
            return (
                <CampaignStepRecommendations
                    nextButtonTouched={nextButtonTouched}
                    setActualAmountOfRecommendedCreators={setActualAmountOfRecommendedCreators}
                />
            );
        case 3:
            return <CampaignStepRecommendationCheckout />;
        default:
            return <Typography variant="h2">Something went wrong</Typography>;
    }
};

export default AppFrame(CampaignSetup);
