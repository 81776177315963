import React, { useEffect } from 'react';

import { getCreatorRegions } from '../../services';

import { Box, Autocomplete, TextField } from '@mui/material';

export default ({ setCountry, handleApplyNewFilterClick }) => {
    const [regionList, setRegionList] = React.useState([]);

    const handleRegionSelect = (event, value) => {
        if (value === null) {
            setCountry(null);
        } else {
            setCountry(value.label);
        }
    };

    useEffect(() => {
        getCreatorRegions().then((res_creators) => {
            const formattedRegions = res_creators.data.map((item, iid) => {
                return {
                    id: iid,
                    label: item,
                };
            });
            setRegionList(formattedRegions);
        });
    }, []);

    return (
        <Autocomplete
            onChange={handleRegionSelect}
            id="creator-region-select"
            sx={{
                width: 300,
                marginBottom: '9px',
                marginRight: '9px',
            }}
            options={regionList}
            autoHighlight
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.label}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleApplyNewFilterClick();
                        }
                    }}
                    sx={{
                        '& .css-1sxvwpc-MuiFormLabel-root-MuiInputLabel-root': {
                            marginTop: '7px',
                            marginLeft: '27px',
                        },

                        '& .MuiOutlinedInput-input': {
                            marginLeft: '25px',
                        },
                        '& .MuiAutocomplete-popper': {
                            position: 'absolute',
                            inset: '0px auto auto 0px',
                            width: '300px',
                            margin: '0px',
                            transform: 'translate3d(1500px, 600.5px, 0px)',
                        },
                        '& .MuiOutlinedInput-root': {
                            marginTop: '-7px',
                            marginLeft: '-10px',
                            borderRadius: '50px',
                            backgroundColor: 'white',
                            height: '70px',
                            '&:hover fieldset': {
                                borderColor: 'black',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'primary',
                            },
                        },
                        width: '310px',
                        borderRadius: '25px',
                        marginTop: '7px',
                    }}
                    {...params}
                    label="region / location"
                    inputProps={{
                        ...params.inputProps,
                    }}
                />
            )}
        />
    );
};
