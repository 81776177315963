import { useEffect, useState } from 'react';
import { Stack, Box, Typography, Container } from '@mui/material';
import { useDispatch } from 'react-redux';

import CampaignRecommendationList from '../../components/CampaignRecommendationList';
import { getSavedRecommendationsByOwnerId } from '../../services/apiRecommendation';
import { setCampaignNameList } from '../../redux/userData/actions';

import roles from '../../hooks/roles';
import useAuthorization from '../../hooks/useAuthorization';
import AppFrame from '../../hoc/AppFrame';
import Notification from '../../components/Notification';
import {
    removeLikedCreatorFromCampaignRecommendation,
    removeMyCreatorsList,
} from '../../services/apiRecommendation';

const MyCampaignRecommendations = () => {
    useAuthorization([roles.BRAND]);

    const dispatch = useDispatch();

    // CONSTANTS
    const notificationMessages = {
        success: 'Creator removed from campaign',
        error: 'Error removing creator from campaign',
    };

    const tableStyles = {
        paddingLeft: '3vw',
        paddingRight: '3vw',
        paddingBottom: '3vw',
        paddingTop: '2vw',
        // borderRadius: '30px',
    };

    // HOOKS
    const [notificationType, setNotificationType] = useState(null);
    const [showAddedCreatornotification, setShowAddedCreatorNotification] = useState(false);
    const [isLoadingCampaignRecommendation, setIsLoadingCampaignRecommendation] = useState(true);
    const [brandCampaignRecommendations, setBrandCampaignRecommendation] = useState([]);

    useEffect(() => {
        getSavedRecommendationsByOwnerId().then((savedRecommendations) => {
            setBrandCampaignRecommendation(savedRecommendations.data);
            setIsLoadingCampaignRecommendation(false);
        });
    }, []);

    const removeList = async (campaignId) => {
        // endpoint call
        const response = await removeMyCreatorsList(campaignId);

        if (!!response && !!response.status && response.status === 200) {
            const updatedCampaignRecommendations = brandCampaignRecommendations.filter(
                (campaign) => campaign._id !== campaignId
            );
            // update state
            setBrandCampaignRecommendation(updatedCampaignRecommendations);

            // update redux
            const campaignNamesWithIds = updatedCampaignRecommendations.map((campaign) => {
                return { _id: campaign._id, title: campaign.title };
            });
            dispatch(setCampaignNameList(campaignNamesWithIds));
        }
    };

    const removeCreatorFromCampaign = async (campaignId, creatorId) => {
        const response = await removeLikedCreatorFromCampaignRecommendation(campaignId, creatorId);
        if (!!response && !!response.status && response.status === 200) {
            setNotificationType('success');

            const updatedCampaignRecommendations = brandCampaignRecommendations.map((campaign) => {
                if (campaign._id === campaignId) {
                    campaign.likedCreators = campaign.likedCreators.filter(
                        (creator) => creator._id !== creatorId
                    );
                }
                return campaign;
            });
            setBrandCampaignRecommendation(updatedCampaignRecommendations);

            setShowAddedCreatorNotification(true);
        } else if (!!response && !!response.status && response.status === 304) {
            setNotificationType('error');
            setShowAddedCreatorNotification(true);
        }
    };

    return (
        <>
            {!isLoadingCampaignRecommendation && (
                <Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                            position: 'fixed',
                            top: 0,
                            left: (theme) => theme.SideBar.width,
                            width: (theme) => theme.SectionTools.width,
                            height: (theme) => theme.NavBar.height,
                            bgcolor: (theme) => theme.palette.background.main,
                            paddingLeft: (theme) => theme.SectionTools.paddingLeft,
                            zIndex: '5',
                        }}
                    >
                        <Typography variant="h2">My Campaigns</Typography>
                    </Stack>
                    <Stack
                        sx={{
                            width: '100%',
                            height: (theme) => theme.SectionTools.height,
                            bgcolor: (theme) => theme.palette.background.main,
                        }}
                    />
                    <Container sx={tableStyles}>
                        {brandCampaignRecommendations.length > 0 ? (
                            <CampaignRecommendationList
                                brandCampaignRecommendations={brandCampaignRecommendations}
                                removeCreatorFromCampaign={removeCreatorFromCampaign}
                                removeList={removeList}
                            />
                        ) : (
                            <Box textAlign="center" paddingTop="20px">
                                <Typography fontSize={20}>
                                    You didn't save any campaign yet. Start a new campaign.
                                </Typography>
                            </Box>
                        )}
                    </Container>
                </Stack>
            )}
            <Notification
                message={notificationMessages[notificationType]}
                open={showAddedCreatornotification}
                onClose={() => {
                    setShowAddedCreatorNotification(false);
                }}
                type={notificationType}
            />
        </>
    );
};

export default AppFrame(MyCampaignRecommendations);
