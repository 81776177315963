import { LogoMark } from './Logotype';
import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Divider,
    SwipeableDrawer,
    IconButton,
    Link as MuiLink,
    List,
    ListItemButton,
    ListItemText,
    Stack,
    Toolbar,
    Typography,
} from '@mui/material';
import {
    Logout,
    Instagram as InstagramIcon,
    LinkedIn as LinkedInIcon,
    Email as EmailIcon,
    Search as SearchIcon,
    Menu as MenuIcon,
} from '@mui/icons-material';
import { ContactIconButton } from './Parts';

const HamburgerMenu = ({
    userReduxState,
    publicUserOptions,
    brandSuperuserOptions,
    handleLogoutClick,
}) => {
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const handleOpenNavMenu = (event) => {
        if (anchorElNav) {
            handleCloseNavMenu();
            return;
        }
        setAnchorElNav(event.currentTarget);
    };

    return (
        <>
            <HamburgerButton handleOpenNavMenu={handleOpenNavMenu} />

            <HamburgerMenuItems anchorElNav={anchorElNav} handleCloseNavMenu={handleCloseNavMenu}>
                {userReduxState.isLoggedIn ? (
                    <>
                        {/* brand superuser options */}
                        {brandSuperuserOptions.map((option) => (
                            <Fragment key={`fr_${option.key}_brand`}>
                                <HamburgerItem
                                    key={`hi_${option.key}_brand`}
                                    onClick={() => navigate(option.href)}
                                >
                                    <option.icon
                                        sx={{
                                            mr: 1,
                                        }}
                                    />
                                    <ListItemText
                                        key={`lit_${option.key}_brand`}
                                        primary={option.label}
                                    />
                                </HamburgerItem>
                                <GreyLine />
                            </Fragment>
                        ))}
                        {/* logout button */}
                        <HamburgerItem key={`hi_li_logout_brand`} onClick={handleLogoutClick}>
                            <Logout sx={{ mr: 1 }} />
                            <ListItemText key="lit_logout_brand" primary="Logout" />
                        </HamburgerItem>
                    </>
                ) : (
                    <>
                        {/* login button */}
                        <HamburgerItem
                            key={`hi_li_login_public`}
                            onClick={() => navigate('/login')}
                        >
                            <SearchIcon sx={{ mr: 1 }} />
                            <ListItemText key="lit_login_public" primary="Login" />
                        </HamburgerItem>
                        <GreyLine />
                        {/* public user options */}
                        {publicUserOptions.map((option) => (
                            <Fragment key={`fr_${option.key}_public`}>
                                <HamburgerItem
                                    key={`hi_${option.key}_public`}
                                    onClick={() => navigate(option.href)}
                                >
                                    <option.icon
                                        sx={{
                                            mr: 1,
                                        }}
                                    />
                                    <ListItemText
                                        key={`li_${option.key}_public`}
                                        primary={option.label}
                                    />
                                </HamburgerItem>
                                <GreyLine />
                            </Fragment>
                        ))}
                    </>
                )}
            </HamburgerMenuItems>
        </>
    );
};

const HamburgerButton = ({ handleOpenNavMenu }) => {
    return (
        <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
            sx={{
                display: { xs: 'block', md: 'none' },
                position: 'fixed',
                right: '16px',
            }}
        >
            <MenuIcon />
        </IconButton>
    );
};

const HamburgerMenuItems = ({ anchorElNav, handleCloseNavMenu, children }) => {
    return (
        <SwipeableDrawer
            anchor="right"
            onOpen={handleCloseNavMenu}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            variant="temporary"
            sx={{
                '& .MuiDrawer-paper': {
                    borderRadius: '0px 0px 0px 16px',
                    boxSizing: 'border-box',
                    width: '80%',
                    height: '95%',
                },
            }}
        >
            <Toolbar
                sx={{
                    height: '200px',
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '2vh',
                }}
            >
                <LogoMark
                    sx={{
                        width: '100px',
                        height: '100px',
                        marginTop: '50px',
                        marginLeft: '0px',
                        marginRight: '0px',
                    }}
                />
            </Toolbar>
            <List
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {children}
            </List>
            <DrawerFooter />
        </SwipeableDrawer>
    );
};

const HamburgerItem = ({ startIcon, children, onClick }) => {
    return (
        <ListItemButton
            onClick={onClick}
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    width: '150px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {children}
            </Box>
        </ListItemButton>
    );
};

const DrawerFooter = () => {
    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: '20px',
                    width: '100%',
                    padding: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {/* Terms and Policy */}
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{
                        width: '100%',
                        marginBottom: '16px',
                    }}
                >
                    <MuiLink
                        href="/privacy"
                        underline="none"
                        sx={{
                            fontSize: {
                                xs: '12px',
                                lg: '14px',
                            },
                            color: '#b9b9b9',
                            '&:hover': { color: 'black' },
                        }}
                    >
                        {'Privacy Policy'}
                    </MuiLink>
                    <Typography
                        sx={{
                            fontSize: {
                                xs: '12px',
                                lg: '14px',
                            },
                            color: '#b9b9b9',
                        }}
                    >
                        {'•'}
                    </Typography>
                    <MuiLink
                        href="/terms"
                        underline="none"
                        sx={{
                            fontSize: {
                                xs: '12px',
                                lg: '14px',
                            },
                            color: '#b9b9b9',
                            '&:hover': { color: 'black' },
                        }}
                    >
                        {'Terms and Conditions'}
                    </MuiLink>
                </Stack>
                {/* Social Media Icons */}
                <Stack width="100%" marginBottom={2} direction="row" justifyContent="center">
                    <ContactIconButton
                        MuiIconComponent={InstagramIcon}
                        href="https://instagram.com/wineconx"
                        title="Instagram"
                    />
                    <ContactIconButton
                        MuiIconComponent={LinkedInIcon}
                        href="https://de.linkedin.com/company/wineconx"
                        title="LinkedIn"
                    />
                    <ContactIconButton
                        MuiIconComponent={EmailIcon}
                        href="mailto:support@wineconx.xom"
                        title="Email"
                    />
                </Stack>
                {/* Copyright Text */}
                <Typography
                    sx={{
                        fontSize: {
                            xs: '12px',
                            lg: '14px',
                        },
                        color: '#b9b9b9',
                    }}
                >
                    ©&nbsp;2023&nbsp;WINECONX
                </Typography>
            </Box>
        </>
    );
};

const GreyLine = () => {
    return (
        <Divider
            sx={{
                width: '100%',
                margin: '0px',
                color: 'black',
            }}
        />
    );
};

export default HamburgerMenu;
