import React from 'react';

import { Tooltip, Box } from '@mui/material';
import Button from '.';

import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';

const SortButton = ({ sortDecreasing, handleSortClick }) => {
    return (
        <Tooltip
            title={
                sortDecreasing ? 'sort by followers - decreasing' : 'sort by followers - increasing'
            }
            placement="top-end"
            enterDelay={400}
            leaveDelay={10}
        >
            <Box>
                <Button
                    variant="contained"
                    onClick={handleSortClick}
                    sx={{
                        marginBottom: '9px',
                        marginRight: '9px',
                        boxShadow: 'none',
                        border: '2px solid #ddd',
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        minWidth: '70px',
                        minHeight: '70px',
                        padding: 0,
                        '&:hover': {
                            backgroundColor: 'white',
                            border: '1px solid #000000de',
                            boxShadow: 'none',
                        },
                        '& svg': {
                            color: 'rgb(118, 118, 118)',
                            width: '36px',
                            height: '36px',
                        },
                    }}
                >
                    {sortDecreasing ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                </Button>
            </Box>
        </Tooltip>
    );
};

export default SortButton;
