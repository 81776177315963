import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga4';

import './config/firebase-config';
import './App.css';
import theme from './styleTheme';
import {
    Home,
    About,
    AdminDashboard,
    AdminInspector,
    BrandSuperUserSearch,
    EmailVerification,
    LandingForm,
    Login,
    Logout,
    MyCampaignRecommendations,
    CommentInstagramTool,
    PaymentSuccess,
    ProfileBrand,
    PurchasedCreators,
    RegistrationSuccessPage,
    ResetPassword,
    ResetPasswordSuccessPage,
    Signup,
    Terms,
    PrivacyPolicy,
    Services,
    SignupCreator,
} from './pages';

import Payment from './components/Payment';
import { DashboardRouter, DescriptionRouter } from './pathRouters';
import RecommendationRouter from './pathRouters/RecommendationRouter';
import useAuthSetup from './hooks/useAuthSetup';
import WaitLoading from './components/WaitLoading';
import Stats from './pages/Stats';

const measurementId = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;

const App = () => {
    useAuthSetup();
    const [loadingState, setLoadingState] = useState(true);
    const userReduxState = useSelector((state) => state.userReducer);

    useEffect(() => {
        setLoadingState(userReduxState.loading);
    }, [userReduxState]);

    useEffect(() => {
        ReactGA.initialize(measurementId);
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <>
                <WaitLoading isLoading={loadingState} />
                {!loadingState && (
                    <Routes>
                        <Route path="/stripe" element={<Payment />} />
                        <Route path="/emailverification" element={<EmailVerification />} />

                        <Route path="/payment/success" element={<PaymentSuccess />} />

                        <Route path="/brand/profile" element={<ProfileBrand />} />
                        <Route path="/brand/campaignlist" element={<MyCampaignRecommendations />} />

                        <Route path="/brand/commentig" element={<CommentInstagramTool />} />
                        <Route path="/brand/stats" element={<Stats />} />

                        <Route path="/dashboard" element={<BrandSuperUserSearch />} />
                        <Route exact path="/register" element={<Navigate to="/signup" />} />
                        <Route path="/admin/dashboard" element={<AdminDashboard />} />
                        <Route path="/admin/creatorinspection" element={<AdminInspector />} />

                        <Route path="/signup" element={<Signup />} />
                        <Route path="/creator_signup" element={<SignupCreator />} />

                        <Route path="/login" element={<Login />} />
                        <Route path="/logout" element={<Logout />} />

                        <Route path="/reset-password" element={<ResetPassword />} />
                        {/* // https://localhost:3000/reset-newpassword */}

                        <Route path="/registrationsuccess" element={<RegistrationSuccessPage />} />
                        <Route
                            path="/reset-password-success"
                            element={<ResetPasswordSuccessPage />}
                        />

                        <Route path="/terms" element={<Terms />} />
                        <Route path="/privacy" element={<PrivacyPolicy />} />

                        <Route path="/home" element={<Home />} />
                        <Route path="/landing" element={<Navigate to="/home" />} />
                        <Route path="/services" element={<Services />} />
                        {/* 
                            <Route path="/about" element={<About />} /> 
                        */}

                        <Route path="/description" element={<DescriptionRouter />} />
                        <Route path="/recommendation" element={<RecommendationRouter />} />
                        <Route path="/mycreators" element={<PurchasedCreators />} />
                        <Route path="/" exact element={<Navigate to="/home" />} />
                        <Route path="*" exact element={<Navigate to="/" />} />
                    </Routes>
                )}
            </>
        </ThemeProvider>
    );
};

export default App;
