import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box, TextField, Alert, Stack, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

import WaitLoading from '../../components/WaitLoading';
import Button from '../../components/Button';
import { confirmPasswordResetFirebase } from '../../services/firebase';

const ResetNewPassword = ({ oobCode }) => {
    const navigate = useNavigate();
    const [displayAlertWrongCredentials, setDisplayAlertWrongCredentials] = useState(false);
    const [showLoadingModal, setShowLoadingModal] = useState(false);

    const validationSchema = yup.object({
        password: yup.string('Enter your password').required('Password is required'),
    });
    const formik = useFormik({
        initialValues: {
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                if (oobCode) {
                    // const verificationResponse =
                    await confirmPasswordResetFirebase(oobCode, values.password);
                }

                setShowLoadingModal(true);

                setTimeout(() => {
                    navigate('/login');
                }, 1500);
            } catch (error) {
                console.error(error);
                setDisplayAlertWrongCredentials(true);
            }
        },
    });

    return (
        <>
            <Box
                sx={{
                    // marginTop: '25vh',
                    margin: '25vh auto 0vh auto',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingBottom: '20%',
                    width: '350px',
                }}
            >
                <form onSubmit={formik.handleSubmit}>
                    <Typography
                        variant="h1"
                        sx={{
                            // marginBottom: '0px',
                            fontSize: '28px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            paddingBottom: '8px',
                        }}
                    >
                        Reset Password
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        fontSize={14}
                        sx={{ textAlign: 'center', marginBottom: '8px' }}
                    >
                        Enter your new password
                    </Typography>

                    <TextField
                        sx={{
                            marginTop: '8px',
                        }}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="password"
                        name="password"
                        label="Password"
                        type="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        autoComplete="current-password"
                    />
                    {displayAlertWrongCredentials && (
                        <Alert severity="warning">
                            Wrong credentials. Try with a different password. Forgot your password?
                            Click here to reset:
                            <a href="/reset-password">Reset Password</a>
                        </Alert>
                    )}

                    <Stack direction="row" justifyContent="space-between">
                        <Button
                            sx={{
                                marginTop: '10px',
                                width: '100%',
                            }}
                            color="error"
                            variant="contained"
                            type="submit"
                            endIcon={<SendIcon />}
                        >
                            RESET
                        </Button>
                    </Stack>
                </form>
            </Box>
            <WaitLoading
                sx={{
                    color: '#fff',
                    zIndex: '20001',
                }}
                isLoading={showLoadingModal}
            />
        </>
    );
};

export default ResetNewPassword;
