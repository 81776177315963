import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import { Grid, Box, Switch, FormControlLabel } from '@mui/material';

const SwitchSelectorStyled = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
    },
}));

const SwitchSelector = ({ name, label, setChosenOptions, defaultValue }) => {
    const [clicked, setClicked] = useState(defaultValue);

    const handleSwitchClick = () => {
        const chosenValue = !clicked;
        setChosenOptions(name, chosenValue);
        setClicked(chosenValue);
    };

    return (
        <FormControlLabel
            label={label}
            control={<SwitchSelectorStyled checked={clicked} />}
            labelPlacement="start"
            onChange={handleSwitchClick}
            sx={{
                justifyContent: 'space-between',
                width: '100%',
                ml: '4px',
                position: 'relative',
                bottom: '-14px',
                color: 'rgba(0, 0, 0, 0.6)',
            }}
        />
    );
};

export default SwitchSelector;
