import { useState, useEffect } from 'react';
import { useNavigate, Link as LinkRRD } from 'react-router-dom';

import { AppBar, Box, Button, Stack, Toolbar } from '@mui/material';
import {
    Instagram as InstagramIcon,
    PeopleAlt as PeopleAltIcon,
    Search as SearchIcon,
    Handshake as HandshakeIcon,
} from '@mui/icons-material';

import { Logo } from '../../pages/Home/Logotype';
import HamburgerMenu from '../../pages/Home/HamburgerMenu';
import roles from '../../hooks/roles';
import AccountMenu from './AccountMenu';
import ScrollHomeButton from '../ScrollHomeButton';
import { logOutFirebase } from '../../services';

const buttonStyle = {
    padding: '16px 54px',
    font: 'normal normal 400 16px/19px Rubik',
    borderRadius: '40px',
};

function ResponsiveAppBar({ userReduxState }) {
    const navigate = useNavigate();
    const [borderColor, setBorderColor] = useState('white');

    const brandSuperuserOptions = [
        {
            label: 'My Creators',
            href: '/brand/campaignlist',
            icon: PeopleAltIcon,
            key: 'li_mycreators',
        },
        {
            label: 'Search',
            href: '/dashboard',
            icon: SearchIcon,
            key: 'li_searchcreator',
        },
        {
            label: 'Comment',
            href: '/brand/commentig',
            icon: InstagramIcon,
            key: 'li_commentig',
        },
        {
            label: 'Statistics',
            href: '/brand/stats',
            icon: InstagramIcon,
            key: 'li_stats',
        },
    ];

    const publicUserOptions = [
        {
            label: 'Services',
            href: '/services',
            icon: HandshakeIcon,
            key: 'li_services',
        },
        // {
        //     label: 'About',
        //     href: '/about',
        //     icon: InfoIcon,
        //     key: 'li_about',
        // },
    ];

    const handleLogoutClick = () => {
        logOutFirebase();
        navigate('/logout');
    };

    useEffect(() => {
        const handleScroll = () => {
            // Threshold scroll distance for color change
            const threshold = 100;
            const scrollDistance = window.scrollY;

            if (scrollDistance > threshold) {
                setBorderColor('#f5f5f5');
            } else {
                setBorderColor('white');
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <AppBar
            position="fixed"
            color="primary"
            sx={{
                paddingLeft: '24px',
                paddingRight: '18px',
                borderBottom: `1px solid ${borderColor}`,
                backgroundColor: 'white',
                color: 'black',
                boxShadow: 'none',
                height: '80px',
            }}
        >
            <Toolbar
                disableGutters
                sx={{
                    height: '100%',
                }}
            >
                <Logo
                    isLogoHomeButton={true}
                    sx={{
                        marginRight: '16px',
                        height: '40px',
                        width: '40px',
                    }}
                />

                <HamburgerMenu
                    userReduxState={userReduxState}
                    publicUserOptions={publicUserOptions}
                    brandSuperuserOptions={brandSuperuserOptions}
                    handleLogoutClick={handleLogoutClick}
                />

                <Box
                    sx={{
                        flexGrow: 1,
                        display: { xs: 'none', md: 'flex' },
                    }}
                >
                    {userReduxState.isLoggedIn &&
                        userReduxState.userType === roles.BRAND &&
                        brandSuperuserOptions.map((option) => (
                            <Button
                                key={`debr_${option.label}`}
                                component={LinkRRD}
                                to={option.href}
                                sx={{ my: 2, color: 'black', display: 'block' }}
                            >
                                {option.label}
                            </Button>
                        ))}

                    {userReduxState.isLoggedIn && userReduxState.userType !== roles.BRAND && (
                        <Button
                            key="Dashboard"
                            component={LinkRRD}
                            to="/dashboard"
                            sx={{ my: 2, color: 'black', display: 'block' }}
                        >
                            Dashboard
                        </Button>
                    )}

                    {!userReduxState.isLoggedIn && (
                        <>
                            {publicUserOptions.map((option) => (
                                <Button
                                    key={`dpub_${option.label}`}
                                    component={LinkRRD}
                                    to={option.href}
                                    sx={{
                                        my: 2,
                                        color: 'black',
                                        display: 'block',
                                    }}
                                >
                                    {option.label}
                                </Button>
                            ))}
                        </>
                    )}
                </Box>

                <Box sx={{ flexGrow: 0 }}>
                    {!userReduxState.isLoggedIn && (
                        <>
                            <Button
                                to="/login"
                                component={LinkRRD}
                                variant="text"
                                sx={{
                                    mx: 2,
                                    px: 2,
                                    display: {
                                        xs: 'none',
                                        md: 'inline-block',
                                    },
                                    color: 'black',
                                }}
                            >
                                Sign in
                            </Button>

                            <ScrollHomeButton
                                to="contact"
                                variant="contained"
                                sx={{
                                    ...buttonStyle,
                                    mx: 2,
                                    px: 4,
                                    fontFamily: 'Rubik',
                                    fontSize: '14px',
                                    height: '48px',
                                    fontWeight: 450,
                                    color: 'white',
                                    backgroundColor: '#de4564',
                                    display: {
                                        xs: 'none',
                                        md: 'inline-block',
                                    },
                                    borderRadius: '25px',
                                    '&:hover': {
                                        backgroundColor: '#e85f7b',
                                    },
                                }}
                            >
                                Contact us now
                            </ScrollHomeButton>
                        </>
                    )}

                    {userReduxState.isLoggedIn && (
                        <Stack direction="row">
                            <Button
                                disabled
                                variant="text"
                                sx={{
                                    mx: 2,
                                    px: 2,
                                    display: {
                                        xs: 'none',
                                        md: 'inline-block',
                                    },
                                    color: 'black',
                                    textShadow: '0px 0px 0px',
                                }}
                            >
                                {userReduxState.user.personal.email}
                            </Button>

                            <AccountMenu userObject={userReduxState} />
                        </Stack>
                    )}
                </Box>
            </Toolbar>

            {/* </Container> */}
        </AppBar>
    );
}

export default ResponsiveAppBar;
