import React from 'react';
import { Grid, Typography } from '@mui/material';

import AppFrame from '../../hoc/AppFrame';
import roles from '../../hooks/roles';
import useAuthorization from '../../hooks/useAuthorization';

const BasicDashboard = () => {
    useAuthorization([roles.BASIC_BRAND_USER]);

    return (
        <Grid container justifyContent="left" spacing={1} paddingTop={10}>
            <Grid item xs={12} md={12}>
                <Typography variant="h1">BASIC Dashboard</Typography>
            </Grid>
        </Grid>
    );
};

export default AppFrame(BasicDashboard);
