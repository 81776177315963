import React from 'react';

import { Avatar, IconButton, Table, TableCell, TableRow } from '@mui/material';
import { Instagram, Delete } from '@mui/icons-material';

import Button from '../Button';

import { averageLikePerPostFormat, commentPerPostFormat } from '../../utils/formatters';

const CreatorRow = ({ campaignId, creator, onDelete }) => {
    return (
        <TableRow>
            <TableCell
                sx={{
                    paddingLeft: '30px',
                }}
            >
                <Avatar
                    sx={{
                        height: '50px',
                        width: '50px',
                    }}
                    alt={`avatar of creator ${creator.name}`}
                    src={creator.profileImage}
                />
            </TableCell>
            <TableCell sx={{ paddingLeft: '3px', paddingRight: '3px' }}>{creator.name}</TableCell>
            <TableCell align="left" sx={{ paddingLeft: '20px', paddingRight: '3px' }}>
                <Button
                    onClick={() =>
                        window.open(`http://instagram.com/${creator.instagramUsername}`, '_blank')
                    }
                    startIcon={<Instagram />}
                    size="small"
                    sx={{
                        color: 'white',
                        backgroundColor: '#8E24AA',
                        '&:hover': {
                            backgroundColor: '#5E35B1',
                        },
                    }}
                >
                    {creator.instagramUsername}
                </Button>
            </TableCell>
            <TableCell align="center" sx={{ paddingLeft: '3px', paddingRight: '3px' }}>
                {creator.country}
            </TableCell>
            <TableCell sx={{ paddingLeft: '3px' }} align="center">
                {commentPerPostFormat(creator.averageCommentsOfLastPosts)}
            </TableCell>
            <TableCell align="center" style={{ paddingLeft: '', paddingRight: '30px' }}>
                {averageLikePerPostFormat(creator.averageLikesOfLastPosts)}
            </TableCell>
            <TableCell align="center" style={{ paddingLeft: '', paddingRight: '30px' }}>
                <IconButton
                    onClick={() => {
                        onDelete(campaignId, creator._id);
                    }}
                    sx={{
                        '&:hover': {
                            color: 'red',
                        },
                    }}
                >
                    <Delete />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default CreatorRow;
