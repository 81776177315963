import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Box, TextField, Typography, Stack } from '@mui/material';

import {
    checkEmailAvailability,
    checkInstagramUsernameAvailability,
    registerNewUserInFirebase,
    sendEmailVerificationFirebase,
    logOutFirebase,
    userCreatorRegistration,
} from '../../services';
import Button from '../../components/Button';

import AppFrame from '../../hoc/AppFrame';
import useAuthorization from '../../hooks/useAuthorization';
import roles from '../../hooks/roles';
import PageMetaData from '../../components/PageMetaData';

const SignupCreator = () => {
    useAuthorization([roles.PUBLIC]);
    const navigate = useNavigate();

    const validationSchema = yup.object({
        claimedInstagramUsername: yup
            .string('Enter instagram username')
            .required('Instagram username is required')
            .test(
                'Unique Instagram Username',
                'Instagram username already in use',
                async function (claimedInstagramUsername) {
                    const { data } = await checkInstagramUsernameAvailability(
                        claimedInstagramUsername
                    );
                    return data.isInstagramUsernameAvailable;
                }
            ),
        firstName: yup
            .string('Enter your first name')
            .min(3, 'The first name should be of minimum 3 characters length')
            .required('First name is required'),
        lastName: yup
            .string('Enter your last name')
            .min(3, 'The last name should be of minimum 3 characters length')
            .required('Last name is required'),
        phone: yup
            .string('Enter your phone number')
            .min(10, 'The phone number should be of minimum 10 characters length'),
        email: yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required')
            .test('Unique Email', 'Email already in use', async function (email) {
                const { data } = await checkEmailAvailability(email);
                return data.isEmailAvailable;
            }),

        password: yup
            .string('Enter your password')
            .min(8, 'Password should be of minimum 8 characters length')
            .required('Password is required'),
    });

    const formik = useFormik({
        initialValues: {
            claimedInstagramUsername: '',
            firstName: '',
            lastName: '',
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                const firebaseResponse = await registerNewUserInFirebase(
                    values.email,
                    values.password
                );

                const response = await userCreatorRegistration(values).catch((error) => {
                    console.log('Error at user registration');
                    console.log(error);
                    console.log(error.message);
                    // TODO: clean entry from firebase
                });
                if (response) {
                    sendEmailVerificationFirebase();
                    navigate('/registrationsuccess');
                }

                logOutFirebase();
            } catch (error) {
                console.log(error);
            }
        },
    });

    return (
        <>
            <PageMetaData
                title={'WineConX | Register'}
                description={
                    'Sign up and start influencer marketing with the best creator matches.'
                }
            />
            <Box
                sx={{
                    // marginTop: '25vh',
                    margin: '96px auto 0vh auto',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingBottom: '20%',
                    width: '450px',
                }}
            >
                <Typography
                    variant="h1"
                    sx={{
                        // marginBottom: '0px',
                        fontSize: '28px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        paddingBottom: '8px',
                    }}
                >
                    Creator Register
                </Typography>
                <Typography
                    variant="subtitle1"
                    fontSize={14}
                    sx={{ textAlign: 'center', marginBottom: '8px' }}
                >
                    Sign up now to access exclusive deals
                    <br /> and benefits from top wineries
                </Typography>

                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        sx={{ marginTop: '4px' }}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="claimedInstagramUsername"
                        name="claimedInstagramUsername"
                        label="Instagram username"
                        value={formik.values.claimedInstagramUsername}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.claimedInstagramUsername &&
                            Boolean(formik.errors.claimedInstagramUsername)
                        }
                        helperText={
                            formik.touched.claimedInstagramUsername &&
                            formik.errors.claimedInstagramUsername
                        }
                    />

                    <TextField
                        sx={{ marginTop: '4px' }}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="firstName"
                        name="firstName"
                        label="First name"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                        helperText={formik.touched.firstName && formik.errors.firstName}
                    />
                    <TextField
                        sx={{ marginTop: '4px' }}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="lastName"
                        name="lastName"
                        label="Last name"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                        helperText={formik.touched.lastName && formik.errors.lastName}
                    />

                    <TextField
                        sx={{ marginTop: '4px' }}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="email"
                        name="email"
                        label="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                    <TextField
                        sx={{ marginTop: '4px' }}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="password"
                        name="password"
                        label="Password"
                        type="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                    />
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Button
                            sx={{
                                marginTop: '10px',
                                width: '100%',
                            }}
                            color="primary"
                            variant="contained"
                            type="submit"
                        >
                            Submit
                        </Button>
                    </Stack>
                </form>
            </Box>
        </>
    );
};

export default AppFrame(SignupCreator);
